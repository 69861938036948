
import BoolFilter from '../BoolFilter/BoolFilter';
import BoolFilterInStock from '../BoolFilterInStock/BoolFilterInStock';
import RangeFilter from '../RangeFilter/RangeFilter';
import SelectFilter from '../SelectFilter/SelectFilter';
import './Filter.css';

const FILTER_TYPES_MAP = {
    'integer': {
        element: (filter) => (<SelectFilter filter={filter} />),
    },
    'string': {
        element: (filter) => (<SelectFilter filter={filter} />),
    },
    'range': {
        element: (filter) => (<RangeFilter filter={filter} />),
    },
    'bool': {
        element: (filter) => filter.filter_type === 'in_stock' ? (<BoolFilterInStock filter={filter}  />) : (<BoolFilter filter={filter} />),
    },
}

function Filter({ filter }) {
    
    const filter_item = FILTER_TYPES_MAP[filter.data_type].element(filter)
    if(!filter_item) return null
    if(filter.data_type === 'range' && filter.features.gte === filter.features.lte) return null
    return (
        <div className='filter'>
            {/* {filter.data_type} */}
            {filter_item}
        </div>
    );
}

export default Filter