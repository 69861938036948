
import { ManagerIcon } from '../../../assets/icons/icons';
import Popup from '../../Popup/Popup';
import './OrderErrorPopup.css';

function OrderErrorPopup({ isOpen, popupName, setOpen }) {
    return (
        <Popup
            isOpen={isOpen}
            popupName={popupName}
            setOpen={setOpen}
        >
            <div className='oreder-error-popup'>
                <ManagerIcon mainClassName={'oreder-error-popup__icon'} fillClassName={'oreder-error-popup__icon_fill'} />
                <p className="oreder-error-popup__text">При создании заказа произошла ошибка</p>
                <p className="oreder-error-popup__subtext">Попробуйте позже, или обратитесь в&nbsp;техническую поддержку customer-support@polza.tech</p>
            </div>
        </Popup>

    );
}

export default OrderErrorPopup