
import { Navigate, useParams } from 'react-router-dom';
import './CustomDocs.css';
import { useContext, useEffect, useState } from 'react';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import mainApi from '../../assets/api/MainApi';
import { ConfigContext } from '../../assets/contexts/configContext';
import { Helmet } from 'react-helmet';

function CustomDocs() {
  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { SHOP_NAME, SHOP_ID_FOR_API } = initialConstants
  const { translit_name } = useParams()
  const [preloaders, setPreloaders] = useState({
    initial: true,
  })
  const [doc, setDoc] = useState(null)

  useEffect(() => {
    if (!translit_name) return
    if (!SHOP_ID_FOR_API) return
    setPreloaders(prevValue => ({
      ...prevValue,
      initial: true,
    }))
    mainApi.getStaticDoc({
      shop_id: SHOP_ID_FOR_API,
      translit_name: translit_name,
    })
      .then((res) => {
        console.log(res)
        setDoc(res)
      })
      .catch((err) => {

        console.log(err)
      })
      .finally(() => {
        setPreloaders(prevValue => ({
          ...prevValue,
          initial: false,
        }))
      })
  }, [translit_name, SHOP_ID_FOR_API])

  return (
    <div className={`custom-docs`}>
      {preloaders.initial ?
        <div className='custom-docs__preloader-box'><MiniPreloader /></div>
        :
        doc? 
        <div className='custom-docs__content'>
          <Helmet>
            <title>{SHOP_NAME} - {doc.name}</title>
          </Helmet>
          {doc.content.css ?
            <style>{doc.content.css}</style>
            :
            null
          }
          <h2 className='custom-docs__title'>{doc.name}</h2>
          <p className='custom-docs__text' dangerouslySetInnerHTML={{
            __html: doc.content.html,
          }}></p>
        </div>
        :
        <Navigate to={`/`} />}

    </div>
  );
}

export default CustomDocs