
import "./HintBox.css";


function HintBox({
    item
}) {
    const { title } = item.data[0]
    if (!title) return (<div className="hint-box__empty"></div>)
    return (
        <div className={`hint-box`} >
            {title ? <p className='hint-box__text' dangerouslySetInnerHTML={{
                __html: title,
            }}></p> : null}
        </div >
    );
}

export default HintBox;