
import { useContext } from 'react';
import { FiltersIcon } from '../../../../assets/icons/icons';
import Filters from '../Filters/Filters';
import './FilterBox.css';
import { FiltersContext } from '../../../../assets/contexts/filtersContext';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';

function FilterBox() {
    const { handleFiltersApply, handleFiltersReset, isLoading } = useContext(FiltersContext)
    return (
        <div className='filter-box'>
            <div className="filter-box__heading">
                <p className='filter-box__title'>Фильтры</p>
                <FiltersIcon
                    mainClassName={'filter-box__icon'}
                    strokeClassName={'filter-box__icon-stroke'}
                />
            </div>
            <div className='filter-box__list'>
                <Filters />
            </div>
            <div className={`filter-box__btns ${isLoading ? 'filter-box__btns_loading' : ''}`}>
                <button className='filter-box__btn filter-box__btn_apply' type='button' onClick={handleFiltersApply}>
                    {isLoading ?
                        <MiniPreloader isLinkColor={true} />
                        :
                        'Применить'
                    }
                </button>
                <button className='filter-box__btn filter-box__btn_reset' type='button' onClick={handleFiltersReset}>
                    Сбросить
                </button>
            </div>
        </div>
    );
}

export default FilterBox