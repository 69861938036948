import { DiscordIcon, DzenIcon, FacebookIcon, InstagramIcon, LinkedInIcon, OdnoklassnikiIcon, OzonIcon, TelegramIcon, TikTokIcon, TwitchIcon, TwitterIcon, VCruIcon, VKontakteIcon, ViberIcon, WBIcon, WebsiteIcon, WhatsAppIcon, YandexMarketIcon, YouTubeIcon } from "../../icons/socialNetworks/socialNetworks"

export const WEBSITE_SOCIAL = {
    type: "website",
    title: "Веб-сайт",
    icon: WebsiteIcon,
}
export const INSTAGRAM_SOCIAL = {
    type: "instagram",
    title: "Instagram",
    icon: InstagramIcon,
}

export const VKONTAKTE_SOCIAL = {
    type: "vkontakte",
    title: "ВКонтакте",
    icon: VKontakteIcon,
}

export const FACEBOOK_SOCIAL = {
    type: "facebook",
    title: "Facebook",
    icon: FacebookIcon,
}

export const YOUTUBE_SOCIAL = {
    type: "youtube",
    title: "YouTube",
    icon: YouTubeIcon,
}

export const TIKTOK_SOCIAL = {
    type: "tiktok",
    title: "TikTok",
    icon: TikTokIcon,
}

export const ODNOKLASSNIKI_SOCIAL = {
    type: "odnoklassniki",
    title: "Одноклассники",
    icon: OdnoklassnikiIcon,
}

export const TWITTER_SOCIAL = {
    type: "twitter",
    title: "Twitter (X)",
    icon: TwitterIcon,
}

export const WHATSAPP_SOCIAL = {
    type: "whatsapp",
    title: "WhatsApp",
    icon: WhatsAppIcon,
}

export const TELEGRAM_SOCIAL = {
    type: "telegram",
    title: "Telegram",
    icon: TelegramIcon,
}

export const VIBER_SOCIAL = {
    type: "viber",
    title: "Viber",
    icon: ViberIcon,
}

export const LINKEDIN_SOCIAL = {
    type: "linkedin",
    title: "LinkedIn",
    icon: LinkedInIcon,
}

export const TWITCH_SOCIAL = {
    type: "twitch",
    title: "Twitch",
    icon: TwitchIcon,
}

export const DZEN_SOCIAL = {
    type: "dzen",
    title: "Дзен",
    icon: DzenIcon,
}

export const VCRU_SOCIAL = {
    type: "vcru",
    title: "VC.ru",
    icon: VCruIcon,
}

export const DISCORD_SOCIAL = {
    type: "discord",
    title: "Discord",
    icon: DiscordIcon,
}

export const YANDEX_MARKET_SOCIAL = {
    type: "yandex-market",
    title: "Яндекс.Маркет",
    icon: YandexMarketIcon,
}

export const WILDBERRIES_SOCIAL = {
    type: "wildberries",
    title: "Wildberries",
    icon: WBIcon,
}

export const OZON_SOCIAL = {
    type: "ozon",
    title: "Ozon",
    icon: OzonIcon,
}


export const DEFAULT_SOCIAL_VALUES = [
    WEBSITE_SOCIAL,
    INSTAGRAM_SOCIAL,
    VKONTAKTE_SOCIAL,
    FACEBOOK_SOCIAL,
    YOUTUBE_SOCIAL,
    TIKTOK_SOCIAL,
    ODNOKLASSNIKI_SOCIAL,
    TWITTER_SOCIAL,
    WHATSAPP_SOCIAL,
    TELEGRAM_SOCIAL,
    VIBER_SOCIAL,
    LINKEDIN_SOCIAL,
    TWITCH_SOCIAL,
    DZEN_SOCIAL,
    VCRU_SOCIAL,
    DISCORD_SOCIAL,
    YANDEX_MARKET_SOCIAL,
    WILDBERRIES_SOCIAL,
    OZON_SOCIAL,
]


export const DEFAULT_SOCIAL_VALUES_MAP = DEFAULT_SOCIAL_VALUES.reduce((acc, social) => {
    acc[social.type] = social;
    return acc;
}, {})

console.log(DEFAULT_SOCIAL_VALUES_MAP)