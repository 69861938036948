import { Link } from "react-router-dom";
import "./Categories.css";
import { useContext } from "react";
import { ConfigContext } from "../../../assets/contexts/configContext";
import { CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT } from "../../../assets/utils/constants";



export function CategoriesNoText() {
    return (
        <Categories
            show_text={false}
        />
    );
}

export function CategoriesWithText() {
    return (
        <Categories
            show_text={true}
        />
    );
}


function Categories({ show_text }) {
    const config = useContext(ConfigContext);

    const { initialConstants, cardTokens } = config
    const { FIRST_LEVEL_CATEGORIES } = initialConstants
    const { image_radius } = cardTokens;


    return (
        <div className={`custom-categories`} >
            {FIRST_LEVEL_CATEGORIES && FIRST_LEVEL_CATEGORIES.length > 0 ?
                FIRST_LEVEL_CATEGORIES.map((item, i) => (
                    <Link className="custom-categories__item"
                        to={`/${CATALOG_MAIN_LINK}/${item.translit_name}`}
                        key={item.translit_name}
                    >
                        <img
                            className="custom-categories__img"
                            src={item.file ? item.file.urls.orig : IMG_PLACEHOLDER_PRODUCT}
                            alt={item.name}
                            style={{
                                borderRadius: image_radius,
                            }}
                        />
                        {show_text ? <p className="custom-categories__title">{item.name}</p> : null}
                    </Link>
                ))
                :
                null}
        </div >
    );
}
