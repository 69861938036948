import { useContext } from 'react';
import './ProductCardHero.css';
import { ConfigContext } from '../../../assets/contexts/configContext';
import { Link, useNavigate } from 'react-router-dom';
import { CART_MAIN_LINK, CATALOG_ITEM_LINK, CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT } from '../../../assets/utils/constants';
import { buildCategoryPath, parsePrice } from '../../../assets/utils/utils';
import { CartIcon } from '../../../assets/icons/icons';
import ImageWithBackground from '../../ImageWithBackground/ImageWithBackground';
import { CartContext } from '../../../assets/contexts/cartContext';

const CARD_LAYOUT_TYPES = {
    TITLE: 'item_name',
    DESCRIPTION: 'description',
    IMAGE: 'image',
    BUTTON: 'price',

}

const PRICE_FORMATS = {
    SEPARATELY: 'separately',
    ON_BUTTON: 'on_button',
}

const BUY_BTN_FORMATS = {
    TEXT: 'text',
    BUTTON: 'button',
}

const categories = [
    {
        "_id": "000000000000000000000000",
        "title": "Demo",
        "translit_name": "Demo",
        "count": 12,
        "is_final": true
    },
    {
        "_id": "000000000000000000000000",
        "title": "Demo2",
        "translit_name": "Demo2",
        "count": 12,
        "is_final": true
    },
    {
        "_id": "000000000000000000000000",
        "title": "Demo3",
        "translit_name": "Demo3",
        "count": 12,
        "is_final": true
    },
]

function ProductCardHero({
    item,
}) {

    const config = useContext(ConfigContext)
    const { cardTokens, initialConstants } = config
    const { CONSTANTS } = initialConstants
    const { ENABLE_PHOTO_AUTOADJUST = true} = CONSTANTS

    const {
        // cards_in_line,
        but_btn_format,
        image_format,
        image_radius,
        layout,
        price_format,
    } = cardTokens

    const filtered_layout = layout.filter((layout_item) => layout_item.type !== CARD_LAYOUT_TYPES.IMAGE)
    const reversedArray = [...item.categories[0].parents].reverse();

    const cart_context = useContext(CartContext)
    const {
        cart,
        handleAddToCart,
        handleRemoveFromCart,
        // setCart,
    } = cart_context


    const inCart = item && cart && cart.find((cart_item) => {
        if (cart_item._id !== item._id) {
            return false; // Skip items with a different ID early
        }
        // If the item has options and selectedOptions are provided, compare them
        if (cart_item.options && cart_item.options.length > 0) {
            return false
        } else {
            // If there are no options to compare, just check the _id
            return true;
        }
    });
    const navigate = useNavigate()
    const in_stock = item ? item.options.length === 1 ? true : item.price_data.in_stock : null
    function handleCartClick(e) {
        if (!in_stock) return
        if (!item.options || item.options.length === 0) {
            e.preventDefault(); // Prevent default button action
            e.stopPropagation(); // Stop the event from bubbling up
            if (inCart) {
                navigate(`/${CART_MAIN_LINK}`);
                return;
            }
            console.log(item)
            const product_to_card = {
                ...item,
                options: null,
                // selected_size: selectedSize,
                count: 1,
            };
            console.log(product_to_card);
            handleAddToCart(product_to_card);
        }
    }

    const in_cart_text = inCart ? 'В корзине ›' : 'В корзину'

    const price = item.options && item.options.length === 1 ? item.options[0].values[0].price : item.price_data.price

    return (
        <Link className='product-card-hero' to={`/${CATALOG_MAIN_LINK}/${buildCategoryPath(reversedArray, item.categories[0].parents.length - 1)}/${CATALOG_ITEM_LINK}/${item._id}`}>

            {ENABLE_PHOTO_AUTOADJUST ?
                < ImageWithBackground
                    className={`product-card-hero__image product-card-hero__image_format_${image_format}`}
                    src={item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}
                    image_radius={image_radius}
                    image_format={image_format}
                    key={`${item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}${item._id}`}
                    metadata={item.files && item.files.length > 0 ? item.files[0].metadata : null}
                />
                :
                <img className={`product-card-hero__image product-card-hero__image_format_${image_format}`} src={item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT} alt=''
                    style={{
                        borderRadius: image_radius,
                    }}
                ></img>
            }

            {item.price_data.discount && item.price_data.discount > 0 ?
                <h3 className='product-card-hero__price-discount'>-{item.price_data.discount}%</h3>
                :
                null
            }
            <div className='product-card-hero__layout'>
                {filtered_layout && filtered_layout.length > 0 ?
                    filtered_layout.map((layout_item, i2) => (
                        <>
                            {
                                layout_item.type === CARD_LAYOUT_TYPES.TITLE ?
                                    <h3 className={`product-card-hero__title ${filtered_layout.length - 1 === i2 ? 'product-card-hero__last-item' : ''}`}>{item.name}</h3>
                                    :
                                    null
                            }

                            {
                                layout_item.type === CARD_LAYOUT_TYPES.DESCRIPTION ?
                                    <p className={`product-card-hero__description ${filtered_layout.length - 1 === i2 ? 'product-card-hero__last-item' : ''}`}>{item.description}</p>
                                    :
                                    null
                            }
                            {
                                layout_item.type === CARD_LAYOUT_TYPES.BUTTON ?
                                    <>
                                        {price_format === PRICE_FORMATS.SEPARATELY ?
                                            <button className={`product-card-hero__button product-card-hero__button_type_${price_format} ${filtered_layout.length - 1 === i2 ? 'product-card-hero__last-item' : ''}`} type='button' onClick={(e) => handleCartClick(e)}>
                                                {item.price_data.discount && item.price_data.discount > 0 ?
                                                    <h3 className='product-card-hero__button-price '>{item.options && item.options.length === 1 ? 'от ' : ''}<span className='product-card-hero__button-price-discounted'>{Number(price)}</span> {parsePrice(Number(price) - Number(price) / 100 * Number(price), 'RUB')}</h3>
                                                    :
                                                    <h3 className='product-card-hero__button-price'>{item.options && item.options.length === 1 ? 'от ' : ''}{parsePrice(price, 'RUB')}</h3>
                                                }
                                                {in_stock ?
                                                    BUY_BTN_FORMATS.TEXT === but_btn_format ?
                                                        <div className='product-card-hero__button-to-cart'>
                                                            <h3 className='product-card-hero__button-to-cart-text'>{in_cart_text}</h3>
                                                        </div>
                                                        :
                                                        <div className='product-card-hero__button-to-cart'>
                                                            <h3 className='product-card-hero__button-to-cart-text'>{in_cart_text}</h3>
                                                            <CartIcon
                                                                mainClassName={'product-card-hero__button-to-cart-icon'}
                                                                fillClassName={'product-card-hero__button-to-cart-icon-fill'}
                                                            />
                                                        </div>
                                                    :
                                                    <div className='product-card__button-to-cart'>
                                                        <p className='product-card__button-to-cart-text'>Нет в наличии</p>
                                                    </div>
                                                }
                                            </button>
                                            : null}

                                        {price_format === PRICE_FORMATS.ON_BUTTON ?
                                            <button className={`product-card-hero__button product-card-hero__button_type_${price_format} ${inCart ? "product-card-hero__button_in-cart" : ""} ${filtered_layout.length - 1 === i2 ? 'product-card-hero__last-item' : ''}`} type='button' onClick={(e) => handleCartClick(e)}>
                                                {item.price_data.discount && item.price_data.discount > 0 ?
                                                    <h3 className='product-card-hero__button-price '>{item.options && item.options.length === 1 ? 'от ' : ''}<span className='product-card-hero__button-price-discounted'>{Number(price)}</span> {parsePrice(Number(price) - Number(price) / 100 * Number(item.price_data.discount), 'RUB')}</h3>
                                                    :
                                                    <h3 className='product-card-hero__button-price'>{item.options && item.options.length === 1 ? 'от ' : ''}{parsePrice(price, 'RUB')}</h3>
                                                }

                                                {in_stock ?
                                                    BUY_BTN_FORMATS.TEXT === but_btn_format ?
                                                        <div className='product-card-hero__button-to-cart'>
                                                            <h3 className='product-card-hero__button-to-cart-text'>{in_cart_text}</h3>
                                                        </div>
                                                        :
                                                        <div className='product-card-hero__button-to-cart'>
                                                            <h3 className='product-card-hero__button-to-cart-text'>{in_cart_text}</h3>
                                                            <CartIcon
                                                                mainClassName={'product-card-hero__button-to-cart-icon'}
                                                                fillClassName={'product-card-hero__button-to-cart-icon-fill'}
                                                            />
                                                        </div>
                                                    :
                                                    <div className='product-card__button-to-cart'>
                                                        <p className='product-card__button-to-cart-text'>Нет в наличии</p>
                                                    </div>
                                                }

                                            </button>
                                            : null}
                                    </>

                                    :
                                    null
                            }
                        </>
                    ))
                    : null}
            </div>

        </Link>
    );
}

export default ProductCardHero