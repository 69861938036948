import React, { useState, useEffect } from 'react';

const ActivityDetector = ({ sendActivityPing }) => {
  const [activityDetected, setActivityDetected] = useState(false);

  useEffect(() => {
    // Функция для обновления состояния активности
    const updateUserActivity = () => setActivityDetected(true);

    // Слушатели событий для обновления состояния активности
    const events = ['scroll', 'mousemove', 'touchstart', 'click'];
    events.forEach(event => window.addEventListener(event, updateUserActivity));

    // Установка интервала для проверки активности и отправки пинга
    const intervalId = setInterval(() => {
      if (activityDetected) {
        sendActivityPing(); // Замените на вашу функцию отправки пинга
        setActivityDetected(false);
      }
    }, 60000); // Проверяем активность каждую минуту

    // Очистка
    return () => {
      clearInterval(intervalId);
      events.forEach(event => window.removeEventListener(event, updateUserActivity));
    };
  }, [activityDetected, sendActivityPing]);

  return null; // Компонент не рендерит ничего в DOM
};

export default ActivityDetector;
