import about from '../../assets/about.webp'

export const ABOUT_US_TEXT = `
<h2 class="static-doc__title">Супермаркет Кошер Гурмэ</h2>

Приглашаем Вас в магазин-импортер Кошер Гурмэ — первый супермаркет кошерной продукции высшего качества в России.

<img class="static-doc__img" src="${about}" alt="" />

Наши преимущества:
- Импорт со всего мира.
- Ассортимент порядка 15 000 товаров.
- Стильный дизайнерский интерьер.
- Собственная парковка.
- 300 м² торговой площади.
- 100 м. от МЕОЦ
`