import { useContext } from 'react';
import './ShowcaseLineHero.css';
import { ConfigContext } from '../../../../assets/contexts/configContext';
import ProductCard from '../../../Catalog/ProductCard/ProductCard';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import ProductCardHero from '../../../Catalog/ProductCardHero/ProductCardHero';
import { getRandomId } from '../../../../assets/utils/utils';

export function ShowcaseLineHero1({
    item
}) {
    return (
        <ShowcaseLineHero
            item={item}
            lines_count={1}
        />
    );
}


export function ShowcaseLineHero2({
    item
}) {
    return (
        <ShowcaseLineHero
            item={item}
            lines_count={2}
        />
    );
}


export function ShowcaseLineHero3({
    item
}) {
    return (
        <ShowcaseLineHero
            item={item}
            lines_count={3}
        />
    );
}




function ShowcaseLineHero({
    item,
    lines_count,
}) {
    const { width } = useWindowSize()
    const config = useContext(ConfigContext)
    const { cardTokens } = config
    const {
        cards_in_line,
    } = cardTokens


    const { title, products } = item.data[0]
    const _id = getRandomId()
    return (
        <div className='showcase-line-hero'>
            {title ? <h2 className='showcase-line-hero__title'>{title}</h2> : null}
            <div className={`showcase-line-hero__items showcase-line-hero__items_cards-in-line-${cards_in_line}`}>
                {products.map((item, i) => (
                    i === 0 ?
                        <ProductCardHero
                            item={item}
                            key={`showcase-line-hero_${_id}products_item_${i}`}
                        />
                        :
                        <ProductCard
                            item={item}
                            key={`showcase-line-hero_${_id}products_item_${i}`}
                        />
                ))}

            </div>
        </div>
    );
}

