

import { Link } from 'react-router-dom';
import { CATALOG_ITEM_LINK, CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT, MAIN_URL } from '../../../../assets/utils/constants';
import './SearchCard.css'
import { ImageOnLoad } from '../../../../assets/utils/ImageOnLoad';
import { buildCategoryPath, getFinalPrice, getMinPrice, getPrice, parsePrice } from '../../../../assets/utils/utils';
import { useContext } from 'react';
import { ConfigContext } from '../../../../assets/contexts/configContext';
import ImageWithBackground from '../../../ImageWithBackground/ImageWithBackground';

const SearchCard = ({ item, closeDropdown }) => {


    const reversedArray = [...item.categories[0].parents].reverse();

    const config = useContext(ConfigContext)
    const { cardTokens, initialConstants } = config
    const { CONSTANTS } = initialConstants
    const { ENABLE_PHOTO_AUTOADJUST = true } = CONSTANTS
    const {
        // cards_in_line,
        but_btn_format,
        image_format,
        image_radius,
        layout,
        price_format,
    } = cardTokens

    // const startOfPrice = item.options[0].values
    // console.log(startOfPrice)
    const price = item.options && item.options.length === 1 ? getMinPrice(item.options[0].values) : item.price_data.price
    const isContactUsOnly = item && item.parameters && item.parameters.length > 0 ? item.parameters.some(param => param.name === '_contact-us-only' && param.value === true) : false;
    return (
        <Link className="search-card" to={`/${CATALOG_MAIN_LINK}/${buildCategoryPath(reversedArray, item.categories[0].parents.length - 1)}/${CATALOG_ITEM_LINK}/${item._id}`} onClick={closeDropdown}>
            {/* <ImageOnLoad className='search-card__img' src={`${item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}`} alt={item.name} /> */}
            {ENABLE_PHOTO_AUTOADJUST ?
                < ImageWithBackground
                    className={`product-card__image product-card__image_format_${image_format}`}
                    src={item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}
                    image_radius={image_radius}
                    image_format={image_format}
                    key={`${item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}${item._id}`}
                    metadata={item.files && item.files.length > 0 ? item.files[0].metadata : null}
                />
                :
                <img
                    className={`product-card__image product-card__image_format_${image_format}`}
                    src={item.files && item.files.length > 0 ? item.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}
                    alt=''
                    style={{
                        borderRadius: image_radius,
                    }}
                ></img>
            }
            <div className='search-card__texts'>
                <p className='search-card__name'>{item.name}</p>
                <p className='search-card__path-box'>
                    {item.categories.map((item2, i) => {
                        return (
                            item2.parents.map((item3, i3) => {
                                return (
                                    <p className='search-card__path'> {item3.name} {i3 !== 0 ? '/' : ''}</p>
                                )
                            })
                        )
                    })}
                </p>
            </div>
            {/* <p className='search-card__price'>{price > 0 ? `${price.toLocaleString('us')}₽` : ''}</p> */}

            {!isContactUsOnly && price ?
                item.price_data.discount && item.price_data.discount > 0 ?
                    <p className='search-card__price'>{item.options && item.options.length === 1 ? 'от ' : ''}<span className='product-card__button-price-discounted'>{Number(price)}</span> {parsePrice(Number(price) - Number(price) / 100 * Number(item.price_data.discount), 'RUB')}</p>
                    :
                    <p className='search-card__price'>{item.options && item.options.length === 1 ? 'от ' : ''}{parsePrice(price, 'RUB')}</p>
                : null
            }

            {/* {item.options && item.price_data.discount > 0 ?
                <p className='search-card__price'>{item.options && item.options.length === 1 ? 'от ' : ''}<span className='product-card__button-price-discounted'>{Number(price)}</span> {parsePrice(Number(price) - Number(price) / 100 * Number(item.price_data.discount), 'RUB')}</p>
                :
                <p className='search-card__price'>{item.options && item.options.length === 1 ? 'от ' : ''}{parsePrice(price, 'RUB')}</p>
            } */}

        </Link>
    )
};

export default SearchCard