import "./Toggle.css";

function Toggle({
    isActive,
    handleClick,
    className,
}) {


    return (
        <button className={`toggle ${className ? className : ''} ${isActive ? 'toggle_active' : ''}`} type="button" onClick={handleClick}>
            <div className="toggle__inside"></div>
        </button>
    );
}

export default Toggle;
