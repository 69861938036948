import { Link } from 'react-router-dom';
import './PromoTextImage.css';
import { IMG_PLACEHOLDER_PROMO } from '../../../../assets/utils/constants';


export function PromoTextImageReverse({
    item
}) {
    return (
        <PromoTextImage
            item={item}
            is_reverse={true}
        />
    );
}


function PromoTextImage({
    item,
    is_reverse = false,
}) {
    console.log(item, item.data[0],)
    const { title, text, link, btn_text, image } = item.data[0]
    console.log({ title, text, link, btn_text, image })
    return (
        <div className={`promo-text-image ${is_reverse? 'promo-text-image_reverse':''}`}>
            <div className='promo-text-image__infos'>
                {title ? <h2 className='promo-text-image__title'>{title}</h2> : null}
                {text ? <p className='promo-text-image__text'>{text}</p> : null}
                {link ?
                    link.startsWith('/') ?
                        <Link className='promo-text-image__link' to={link}>
                            <h3 className='promo-text-image__link-text'>{btn_text ? btn_text : 'Перейти'}</h3>
                        </Link>
                        :
                        <a className='promo-text-image__link' href={link} target='_blank' rel='noreferrer'>
                            <h3 className='promo-text-image__link-text'>{btn_text ? btn_text : 'Перейти'}</h3>
                        </a>
                    : null}
            </div>
            <img
                className="promo-text-image__img"
                // src={`${MAIN_URL}/get-file/${item.file_path.orig}`}
                src={`${image ? image.urls.orig : IMG_PLACEHOLDER_PROMO}`}
                alt=""></img>
        </div>
    );
}

export default PromoTextImage