import { useContext, useEffect, useRef, useState } from "react";
import "./Header.css";
import { ConfigContext } from "../../assets/contexts/configContext";
import { Link, NavLink } from "react-router-dom";
import {
  CART_MAIN_LINK,
  CATALOG_MAIN_LINK,
} from "../../assets/utils/constants";
import { CartIcon, CloseIcon, MenuIcon, SearchIcon } from "../../assets/icons/icons";
import mainApi from "../../assets/api/MainApi";
import CatalogDrop from "./CatalogDrop/CatalogDrop";
import MenuPopup from "./MenuPopup/MenuPopup";
import { CartContext } from "../../assets/contexts/cartContext";
import useScrollDirection from "../../assets/hooks/useScrollDirection";
import { getURLRelativePath, isExternalURL } from "../../assets/utils/utils";
import Search from "../Search/Search";
import useWindowSize from "../../assets/hooks/useWindowSize";

function Header() {
  const scrollDirection = useScrollDirection();
  const { width } = useWindowSize();
  const [searchOpen, setSearchOpen] = useState(false)

  const config = useContext(ConfigContext);
  const cart_context = useContext(CartContext)
  const {
    cart,
    handleAddToCart,
    handleRemoveFromCart,
    setCart,
  } = cart_context
  const { initialConstants } = config
  const { FIRST_LEVEL_CATEGORIES, HEADER_LINKS } = initialConstants

  const catalogBtnRef = useRef(null);
  const linksRef = useRef(null);

  const [isCatalogOpen, setCatalogOpen] = useState(false);
  // const [categories, setCategories] = useState(FIRST_LEVEL_CATEGORIES && FIRST_LEVEL_CATEGORIES.length > 0 && Array.isArray(FIRST_LEVEL_CATEGORIES) ? FIRST_LEVEL_CATEGORIES : []);
  const [topCategories, setTopCategories] = useState(undefined);

  // useEffect(() => {
  //   mainApi
  //     .getCategories({
  //       limit: 25,
  //       shop_id: "65ae789bcb5f6a9f66e457aa",
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setCategories(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition);
      setTimeout(() => {
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
        document.body.style.height = "100%";
      }, 10);

      console.log(window.pageYOffset);
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "unset";
      document.body.style.width = "unset";
      document.body.style.height = "unset";
      document.body.style.top = `unset`;
      console.log(popupScrollPosition);
      window.scrollTo(0, popupScrollPosition);
      setScrollWindowPosition(popupScrollPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const [visibleCategories, setVisibleCategories] = useState([]);

  useEffect(() => {
    if (!HEADER_LINKS || HEADER_LINKS.length === 0) return
    const updateVisibleLinks = () => {
      if (linksRef.current) {
        const maxAllowedWidth = linksRef.current.offsetWidth;
        if (maxAllowedWidth <= 0) return
        let totalWidth = 0;
        const linksToShow = [];

        HEADER_LINKS.forEach((link, index) => {
          const linkElement = document.createElement('div');
          linkElement.className = 'link';
          linkElement.style.visibility = 'hidden';
          linkElement.style.position = 'absolute';
          linkElement.innerText = link.name;
          document.body.appendChild(linkElement);
          const linkWidth = linkElement.offsetWidth + 28 * 2;
          document.body.removeChild(linkElement);

          if (totalWidth + linkWidth <= maxAllowedWidth) {
            linksToShow.push(link);
            totalWidth += linkWidth;
          }
        });

        setVisibleCategories(linksToShow);
      }
    };

    updateVisibleLinks();
    window.addEventListener('resize', updateVisibleLinks);

    return () => window.removeEventListener('resize', updateVisibleLinks);
  }, [HEADER_LINKS]);

  return (
    <>

      <header className={`header  ${scrollDirection === "down" ? searchOpen ? "header_show" : "header_hide" : "header_show"}`}>
        <CatalogDrop
          isOpened={isCatalogOpen}
          setOpened={setCatalogOpen}
          categories={FIRST_LEVEL_CATEGORIES}
          catalogBtnRef={catalogBtnRef}
        />
        <MenuPopup
          isOpened={isMenuOpen}
          setOpened={setMenuOpen}
          categories={FIRST_LEVEL_CATEGORIES}
        />
        <div className="header__content">
          <Link className="header__logo" to={"/"}>
            <img
              className="header__logo-img"
              src={config.platfromTokens.logo.url}
              alt=""
            />
          </Link>
          <div className="header__catalog-with-links">
            <div className="header__catalog">
              {/* <div className='header__categories'>
                        {categories.map((category, i) => (
                            <Link className='header__category' to={`/${CATALOG_MAIN_LINK}/${category.translit_name}`}>
                                {category.title}
                            </Link>
                        ))}
                    </div> */}
              {/* <button className='header__catalog-btn' type='button'>
                        <h3 className='header__catalog-btn-text'>Каталог товаров</h3>
                    </button> */}

              <button
                className="header__catalog-btn"
                type="button"
                ref={catalogBtnRef}
              >
                <CloseIcon
                  mainClassName={`header__catalog-btn-icon header__catalog-btn-icon_close-catalog ${isCatalogOpen
                    ? "header__catalog-btn-icon_close-catalog-open"
                    : ""
                    }`}
                  fillClassName={"header__catalog-btn-icon-fill"}
                />
                <svg className={`header__catalog-btn-icon`} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.4123 3H4.6123C4.05225 3 3.77222 3 3.55831 3.10899C3.37015 3.20487 3.21717 3.35785 3.12129 3.54601C3.0123 3.75992 3.0123 4.03995 3.0123 4.6V8.4C3.0123 8.96005 3.0123 9.24008 3.12129 9.45399C3.21717 9.64215 3.37015 9.79513 3.55831 9.89101C3.77222 10 4.05225 10 4.6123 10H8.4123C8.97235 10 9.25238 10 9.46629 9.89101C9.65445 9.79513 9.80743 9.64215 9.90331 9.45399C10.0123 9.24008 10.0123 8.96005 10.0123 8.4V4.6C10.0123 4.03995 10.0123 3.75992 9.90331 3.54601C9.80743 3.35785 9.65445 3.20487 9.46629 3.10899C9.25238 3 8.97235 3 8.4123 3Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M19.4123 3H15.6123C15.0522 3 14.7722 3 14.5583 3.10899C14.3701 3.20487 14.2172 3.35785 14.1213 3.54601C14.0123 3.75992 14.0123 4.03995 14.0123 4.6V8.4C14.0123 8.96005 14.0123 9.24008 14.1213 9.45399C14.2172 9.64215 14.3701 9.79513 14.5583 9.89101C14.7722 10 15.0522 10 15.6123 10H19.4123C19.9724 10 20.2524 10 20.4663 9.89101C20.6545 9.79513 20.8074 9.64215 20.9033 9.45399C21.0123 9.24008 21.0123 8.96005 21.0123 8.4V4.6C21.0123 4.03995 21.0123 3.75992 20.9033 3.54601C20.8074 3.35785 20.6545 3.20487 20.4663 3.10899C20.2524 3 19.9724 3 19.4123 3Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M19.4123 14H15.6123C15.0522 14 14.7722 14 14.5583 14.109C14.3701 14.2049 14.2172 14.3578 14.1213 14.546C14.0123 14.7599 14.0123 15.0399 14.0123 15.6V19.4C14.0123 19.9601 14.0123 20.2401 14.1213 20.454C14.2172 20.6422 14.3701 20.7951 14.5583 20.891C14.7722 21 15.0522 21 15.6123 21H19.4123C19.9724 21 20.2524 21 20.4663 20.891C20.6545 20.7951 20.8074 20.6422 20.9033 20.454C21.0123 20.2401 21.0123 19.9601 21.0123 19.4V15.6C21.0123 15.0399 21.0123 14.7599 20.9033 14.546C20.8074 14.3578 20.6545 14.2049 20.4663 14.109C20.2524 14 19.9724 14 19.4123 14Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.4123 14H4.6123C4.05225 14 3.77222 14 3.55831 14.109C3.37015 14.2049 3.21717 14.3578 3.12129 14.546C3.0123 14.7599 3.0123 15.0399 3.0123 15.6V19.4C3.0123 19.9601 3.0123 20.2401 3.12129 20.454C3.21717 20.6422 3.37015 20.7951 3.55831 20.891C3.77222 21 4.05225 21 4.6123 21H8.4123C8.97235 21 9.25238 21 9.46629 20.891C9.65445 20.7951 9.80743 20.6422 9.90331 20.454C10.0123 20.2401 10.0123 19.9601 10.0123 19.4V15.6C10.0123 15.0399 10.0123 14.7599 9.90331 14.546C9.80743 14.3578 9.65445 14.2049 9.46629 14.109C9.25238 14 8.97235 14 8.4123 14Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <h3 className="header__catalog-btn-text">Каталог</h3>
              </button>
            </div>
            <div className="search_pc">
              <button className="header__input-icon" onClick={() => setSearchOpen(true)} type="button">
                <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
              </button>
              <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={linksRef && linksRef.current ? linksRef.current.offsetWidth + 28 + 20 : '700px'} />
            </div>

            {HEADER_LINKS && HEADER_LINKS.length > 0 ?
              <div className="header__links" ref={linksRef}>
                {visibleCategories.map((link) => {
                  if (isExternalURL(link.url)) {
                    return (
                      <Link
                        key={link._id}
                        className={`header__link`}
                        to={link.url}
                        target={'_blank'} // Conditionally set target attribute
                        rel={'noopener noreferrer'} // Add rel for security if external
                      >
                        {link.name}
                      </Link>
                    )
                  } else {
                    const relativePath = getURLRelativePath(link.url);
                    return (
                      <NavLink
                        key={link._id}
                        className={({ isActive }) => {
                          return `header__link ${isActive ? 'header__link_active' : ''}`
                        }}
                        to={relativePath}
                      >
                        {link.name}
                      </NavLink>
                    )
                  }
                })}
              </div>
              : null
            }
          </div>

          <div className="header__cart-and-menu">
            <button className="header__search-icon-mobile" onClick={() => setSearchOpen(true)} type="button">
              <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
            </button>
            <div className="header__cart-box">
              {cart && cart.length > 0 ? (
                <p className="header__cart-count">{cart.length}</p>
              ) : null}
              <Link className="header__cart" to={`/${CART_MAIN_LINK}`}>
                <svg className="header__cart-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.9996 8C15.9996 9.06087 15.5782 10.0783 14.828 10.8284C14.0779 11.5786 13.0605 12 11.9996 12C10.9387 12 9.92131 11.5786 9.17116 10.8284C8.42102 10.0783 7.99959 9.06087 7.99959 8M3.63281 7.40138L2.93281 15.8014C2.78243 17.6059 2.70724 18.5082 3.01227 19.2042C3.28027 19.8157 3.74462 20.3204 4.33177 20.6382C5.00006 21 5.90545 21 7.71623 21H16.283C18.0937 21 18.9991 21 19.6674 20.6382C20.2546 20.3204 20.7189 19.8157 20.9869 19.2042C21.2919 18.5082 21.2167 17.6059 21.0664 15.8014L20.3664 7.40138C20.237 5.84875 20.1723 5.07243 19.8285 4.48486C19.5257 3.96744 19.0748 3.5526 18.5341 3.29385C17.92 3 17.141 3 15.583 3L8.41623 3C6.85821 3 6.07921 3 5.4651 3.29384C4.92433 3.5526 4.47349 3.96744 4.17071 4.48486C3.82689 5.07243 3.76219 5.84875 3.63281 7.40138Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <h3 className="header__cart-text">Корзина</h3>
              </Link>
            </div>
            <button
              className="header__menu"
              type="button"
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <MenuIcon
                mainClassName={"header__menu-icon"}
                fillClassName={"header__menu-icon-fill"}
              />
            </button>
          </div>

          <div className="search_mobile">

            <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={`100%`} />
          </div>

        </div>
      </header>
    </>
  );
}

export default Header;
