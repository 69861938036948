
import "./CustomAdvantages.css";


function CustomAdvantages() {

    return (
        <div className={`custom-advantages`} >
            <div className="custom-advantages__card">
                <svg className="custom-advantages__card-icon" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.12" d="M29.42 27.5H15.1568C13.5066 27.5 12.6814 27.5 12.0189 27.1956C11.4351 26.9274 10.9413 26.4962 10.5968 25.9539C10.2059 25.3385 10.0947 24.5209 9.8722 22.8857L8.11905 10H35.5554C36.1083 10 36.3847 10 36.5792 10.1134C36.7499 10.2128 36.8796 10.3696 36.9454 10.5558C37.0204 10.7681 36.9686 11.0397 36.8652 11.5828L34.6591 23.1646C34.3652 24.7078 34.2182 25.4794 33.8205 26.057C33.4697 26.5664 32.9842 26.9681 32.4183 27.2174C31.7764 27.5 30.9909 27.5 29.42 27.5Z" fill="#83C452" />
                    <path d="M3.83333 3.33333H6.01027C6.4203 3.33333 6.62531 3.33333 6.7903 3.40873C6.93569 3.47517 7.0589 3.58204 7.14524 3.71657C7.24322 3.86923 7.27221 4.07219 7.3302 4.4781L8.11904 9.99999M8.11904 9.99999L9.87219 22.8857C10.0947 24.5209 10.2059 25.3385 10.5968 25.9539C10.9413 26.4962 11.4351 26.9274 12.0189 27.1956C12.6814 27.5 13.5066 27.5 15.1568 27.5H29.42C30.9909 27.5 31.7764 27.5 32.4183 27.2174C32.9842 26.9681 33.4697 26.5664 33.8205 26.057C34.2182 25.4794 34.3652 24.7078 34.6591 23.1646L36.8652 11.5828C36.9686 11.0397 37.0204 10.7681 36.9454 10.5558C36.8796 10.3696 36.7499 10.2128 36.5792 10.1134C36.3847 9.99999 36.1083 9.99999 35.5554 9.99999H8.11904ZM17.1667 35C17.1667 35.9205 16.4205 36.6667 15.5 36.6667C14.5795 36.6667 13.8333 35.9205 13.8333 35C13.8333 34.0795 14.5795 33.3333 15.5 33.3333C16.4205 33.3333 17.1667 34.0795 17.1667 35ZM30.5 35C30.5 35.9205 29.7538 36.6667 28.8333 36.6667C27.9129 36.6667 27.1667 35.9205 27.1667 35C27.1667 34.0795 27.9129 33.3333 28.8333 33.3333C29.7538 33.3333 30.5 34.0795 30.5 35Z" stroke="#83C452" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className="custom-advantages__card-text">Более 1400 товаров</p>
            </div>
            <div className="custom-advantages__card">
                <svg className="custom-advantages__card-icon" width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.12">
                        <path d="M24.7864 30.5C25.0473 30.5 25.1778 30.5 25.2962 30.4641C25.401 30.4322 25.4985 30.38 25.5832 30.3105C25.6788 30.2319 25.7511 30.1234 25.8958 29.9063L27.9442 26.8337C28.2495 26.3758 28.4021 26.1468 28.416 25.9228C28.4282 25.7263 28.3703 25.5318 28.2528 25.3738C28.1188 25.1938 27.8659 25.0854 27.36 24.8686L23 23L21.5971 22.5323C21.2081 22.4027 21.0136 22.3379 20.8332 22.3572C20.6743 22.3743 20.5236 22.4367 20.3991 22.537C20.2579 22.6509 20.1662 22.8342 19.9828 23.201L19.0803 25.006C18.9892 25.1883 18.9436 25.2795 18.9213 25.3756C18.9015 25.4607 18.8953 25.5485 18.9028 25.6356C18.9113 25.7339 18.9435 25.8306 19.008 26.0239L20.1961 29.5883C20.3054 29.9162 20.36 30.0801 20.4614 30.2013C20.5509 30.3083 20.6658 30.3911 20.7956 30.4422C20.9427 30.5 21.1154 30.5 21.461 30.5H24.7864Z" fill="#83C452" />
                        <path d="M24.2842 5.40815C24.3108 5.00992 24.324 4.81081 24.2555 4.63669C24.1979 4.49052 24.0842 4.34694 23.9551 4.25739C23.8013 4.15072 23.6187 4.12036 23.2534 4.05964C22.3578 3.91078 21.4381 3.83333 20.5003 3.83333C14.4137 3.83333 9.0891 7.09606 6.18014 11.968C5.98757 12.2905 5.89129 12.4517 5.87397 12.6402C5.85943 12.7984 5.89736 12.9797 5.97405 13.1188C6.06544 13.2846 6.23011 13.4022 6.55947 13.6374L12.7737 18.0761C13.1456 18.3417 13.3315 18.4745 13.5261 18.5051C13.6973 18.532 13.8727 18.5049 14.0278 18.4275C14.204 18.3395 14.3411 18.1567 14.6153 17.7911L16.1255 15.7775C16.203 15.6741 16.2418 15.6224 16.2874 15.5778C16.3278 15.5383 16.3722 15.5029 16.4199 15.4723C16.4735 15.4378 16.5326 15.4116 16.6507 15.3591L23.0983 12.4935C23.3656 12.3747 23.4992 12.3153 23.6 12.2225C23.6891 12.1405 23.7593 12.04 23.8056 11.9281C23.858 11.8015 23.8677 11.6556 23.8872 11.3638L24.2842 5.40815Z" fill="#83C452" />
                    </g>
                    <path d="M5.62665 12.9714L12.7734 18.0761C13.1453 18.3417 13.3312 18.4745 13.5258 18.5051C13.697 18.532 13.8724 18.5049 14.0275 18.4275C14.2037 18.3395 14.3408 18.1567 14.615 17.7911L16.1252 15.7775C16.2027 15.6741 16.2415 15.6224 16.2871 15.5778C16.3275 15.5383 16.3719 15.5029 16.4196 15.4723C16.4732 15.4378 16.5323 15.4116 16.6504 15.3591L23.098 12.4935C23.3653 12.3747 23.4989 12.3153 23.5997 12.2225C23.6888 12.1405 23.759 12.04 23.8053 11.9281C23.8577 11.8015 23.8674 11.6556 23.8869 11.3638L24.359 4.28234M23 23L27.36 24.8686C27.8659 25.0854 28.1188 25.1938 28.2528 25.3738C28.3704 25.5318 28.4282 25.7263 28.416 25.9228C28.4021 26.1468 28.2495 26.3758 27.9442 26.8337L25.8958 29.9063C25.7511 30.1234 25.6788 30.2319 25.5832 30.3105C25.4985 30.38 25.401 30.4322 25.2962 30.4641C25.1778 30.5 25.0473 30.5 24.7864 30.5H21.461C21.1154 30.5 20.9427 30.5 20.7956 30.4422C20.6658 30.3911 20.5509 30.3083 20.4614 30.2013C20.36 30.0801 20.3054 29.9162 20.1961 29.5883L19.008 26.0239C18.9435 25.8306 18.9113 25.7339 18.9028 25.6356C18.8953 25.5485 18.9015 25.4607 18.9213 25.3756C18.9436 25.2795 18.9892 25.1883 19.0803 25.006L19.9829 23.201C20.1662 22.8342 20.2579 22.6509 20.3991 22.537C20.5236 22.4367 20.6743 22.3743 20.8332 22.3572C21.0136 22.3379 21.2081 22.4027 21.5971 22.5323L23 23ZM37.1667 20.5C37.1667 29.7047 29.7048 37.1667 20.5 37.1667C11.2953 37.1667 3.83334 29.7047 3.83334 20.5C3.83334 11.2952 11.2953 3.83333 20.5 3.83333C29.7048 3.83333 37.1667 11.2952 37.1667 20.5Z" stroke="#83C452" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className="custom-advantages__card-text">Уникальные продукты со&nbsp;всего мира</p>
            </div>
            <div className="custom-advantages__card">
                <svg className="custom-advantages__card-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.12" d="M20 36.6668C29.2047 36.6668 36.6666 29.2049 36.6666 20.0002C36.6666 10.7954 29.2047 3.3335 20 3.3335C10.7952 3.3335 3.33331 10.7954 3.33331 20.0002C3.33331 29.2049 10.7952 36.6668 20 36.6668Z" fill="#83C452" />
                    <path d="M13.3333 23.3335C13.3333 23.3335 15.8333 26.6668 20 26.6668C24.1666 26.6668 26.6666 23.3335 26.6666 23.3335M28.3333 15.4002C27.675 16.2085 26.775 16.6668 25.8333 16.6668C24.8916 16.6668 24.0166 16.2085 23.3333 15.4002M16.6666 15.4002C16.0083 16.2085 15.1083 16.6668 14.1666 16.6668C13.225 16.6668 12.35 16.2085 11.6666 15.4002M36.6666 20.0002C36.6666 29.2049 29.2047 36.6668 20 36.6668C10.7952 36.6668 3.33331 29.2049 3.33331 20.0002C3.33331 10.7954 10.7952 3.3335 20 3.3335C29.2047 3.3335 36.6666 10.7954 36.6666 20.0002Z" stroke="#83C452" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className="custom-advantages__card-text">Приятное обслуживание</p>
            </div>
            <div className="custom-advantages__card">
                <svg className="custom-advantages__card-icon" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.12" d="M30.3353 8.33101C30.6785 9.16105 31.3373 9.82083 32.1668 10.1653L35.0754 11.3701C35.9055 11.714 36.565 12.3735 36.9088 13.2036C37.2527 14.0337 37.2527 14.9664 36.9088 15.7965L35.7049 18.7032C35.3609 19.5336 35.3604 20.4673 35.706 21.2974L36.9078 24.2032C37.0783 24.6143 37.166 25.055 37.1661 25.5001C37.1662 25.9452 37.0786 26.3859 36.9083 26.7971C36.7379 27.2083 36.4883 27.5819 36.1735 27.8965C35.8588 28.2112 35.4851 28.4608 35.0738 28.631L32.1673 29.8349C31.3373 30.1781 30.6775 30.8369 30.3331 31.6665L29.1283 34.5752C28.7845 35.4053 28.125 36.0648 27.2949 36.4087C26.4648 36.7525 25.5321 36.7525 24.7021 36.4087L21.7955 35.2047C20.9654 34.8617 20.0331 34.8624 19.2035 35.2066L16.2949 36.4098C15.4652 36.7528 14.5334 36.7525 13.704 36.4089C12.8746 36.0654 12.2155 35.4066 11.8714 34.5774L10.6662 31.6678C10.323 30.8378 9.66428 30.178 8.83479 29.8335L5.92615 28.6287C5.09643 28.285 4.43713 27.6259 4.09318 26.7963C3.74922 25.9666 3.74875 25.0344 4.09188 24.2044L5.29581 21.2977C5.6388 20.4676 5.6381 19.5352 5.29387 18.7056L4.09166 15.7948C3.92121 15.3837 3.83345 14.943 3.83337 14.4979C3.8333 14.0528 3.92093 13.6121 4.09125 13.2009C4.26156 12.7897 4.51123 12.4161 4.82599 12.1014C5.14074 11.7868 5.51441 11.5372 5.92565 11.367L8.8322 10.163C9.66149 9.82011 10.3208 9.16216 10.6655 8.33359L11.8703 5.42485C12.2142 4.59474 12.8737 3.93522 13.7037 3.59138C14.5338 3.24754 15.4665 3.24754 16.2966 3.59138L19.2031 4.79535C20.0332 5.13835 20.9655 5.13765 21.7951 4.79341L24.705 3.59324C25.535 3.2496 26.4674 3.24967 27.2973 3.59344C28.1273 3.93721 28.7867 4.59654 29.1305 5.42644L30.3357 8.33605L30.3353 8.33101Z" fill="#83C452" />
                    <path d="M15.5 20.0002L18.8334 23.3335L26.3334 15.8335M30.3353 8.33101C30.6785 9.16105 31.3373 9.82083 32.1668 10.1653L35.0754 11.3701C35.9055 11.714 36.565 12.3735 36.9088 13.2036C37.2527 14.0337 37.2527 14.9664 36.9088 15.7965L35.7049 18.7032C35.3609 19.5336 35.3604 20.4673 35.706 21.2974L36.9078 24.2032C37.0783 24.6143 37.1661 25.055 37.1661 25.5001C37.1662 25.9451 37.0786 26.3859 36.9083 26.7971C36.7379 27.2083 36.4883 27.5819 36.1735 27.8965C35.8588 28.2112 35.4851 28.4608 35.0738 28.631L32.1673 29.8349C31.3373 30.1781 30.6775 30.8369 30.3331 31.6665L29.1283 34.5752C28.7845 35.4053 28.125 36.0648 27.2949 36.4087C26.4648 36.7525 25.5321 36.7525 24.7021 36.4087L21.7955 35.2047C20.9654 34.8617 20.0331 34.8624 19.2035 35.2066L16.2949 36.4098C15.4652 36.7528 14.5334 36.7525 13.704 36.4089C12.8746 36.0654 12.2155 35.4066 11.8714 34.5774L10.6662 31.6678C10.323 30.8378 9.66428 30.178 8.83479 29.8335L5.92615 28.6287C5.09643 28.285 4.43713 27.6259 4.09318 26.7963C3.74922 25.9666 3.74875 25.0344 4.09188 24.2044L5.29581 21.2977C5.6388 20.4676 5.6381 19.5352 5.29387 18.7056L4.09166 15.7948C3.92121 15.3837 3.83345 14.943 3.83337 14.4979C3.8333 14.0528 3.92093 13.6121 4.09125 13.2009C4.26156 12.7897 4.51123 12.4161 4.82599 12.1014C5.14074 11.7868 5.51441 11.5372 5.92565 11.367L8.8322 10.163C9.66149 9.82011 10.3208 9.16216 10.6655 8.33359L11.8703 5.42485C12.2142 4.59474 12.8737 3.93522 13.7037 3.59138C14.5338 3.24754 15.4665 3.24754 16.2966 3.59138L19.2031 4.79535C20.0332 5.13835 20.9655 5.13765 21.7951 4.79341L24.705 3.59324C25.535 3.2496 26.4674 3.24967 27.2973 3.59344C28.1273 3.93721 28.7867 4.59654 29.1305 5.42644L30.3357 8.33605L30.3353 8.33101Z" stroke="#83C452" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <p className="custom-advantages__card-text">Гарантия качества</p>
            </div>
        </div >
    );
}

export default CustomAdvantages;