import Slider from "@mui/material/Slider";
import './BoolFilter.css';
import { useContext, useState } from "react";
import { FiltersContext } from "../../../../../assets/contexts/filtersContext";


function BoolFilter({ filter }) {
    const { setFilterValues } = useContext(FiltersContext)

    const handleToggle = (value) => {
        setFilterValues(prevValue => prevValue.map((item) => {
            if (item._id !== filter._id) return item
            else {
                return {
                    ...item,
                    value: value,
                }
            }
        }))
    };

    return (
        <div className='bool-filter'>
            <div className='bool-filter__heading'>
                <p className='bool-filter__name'>{filter.name}</p>
            </div>
            <div className="bool-filter__selector" >
                <button className={`bool-filter__selector-item ${filter.value ? 'bool-filter__selector-item_selected' : ''}`} type="button" onClick={()=>{
                    handleToggle(true)
                }}>
                    Да
                </button>
                <button className={`bool-filter__selector-item ${filter.value === false ? 'bool-filter__selector-item_selected' : ''}`} type="button" onClick={()=>{
                    handleToggle(false)
                }}>
                    Нет
                </button>
            </div>
        </div>
    );
}

export default BoolFilter