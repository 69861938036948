import { useContext, useEffect, useRef, useState } from "react";
import "./Search.css";
import SearchDrop from "./SearchDrop/SearchDrop";
import { CloseSearch, SearchIcon } from "../../assets/icons/icons";
import { motion } from 'framer-motion';
import mainApi from "../../assets/api/MainApi";
import useWindowSize from "../../assets/hooks/useWindowSize";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../assets/contexts/configContext";

function Search({ searchOpen, setSearchOpen, maxWidth }) {
    const config = useContext(ConfigContext)
    const { initialConstants } = config
    const { SHOP_ID_FOR_API, SHOP_NAME } = initialConstants
    const navigate = useNavigate()

    const searchRef = useRef()
    const [searchValue, setSearchValue] = useState(null)
    const [isSearchPreloaderVisible, setSearchPreloaderVisible] = useState(false)
    const [isDropDownOpened, setDropDownOpened] = useState(false)
    const [searchItems, setSearchItems] = useState(undefined)
    const { width } = useWindowSize();
    const location = useLocation()

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(searchValue)
            if (!searchValue) return
            mainApi.getSearch({
                shop_id: SHOP_ID_FOR_API,
                string: searchValue.toLowerCase(),
                limit: 5,
            })
                .then((res) => {
                    console.log(res.data)
                    setSearchItems(res.data)
                    // setSearchItems(RESULT_SEARCH)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setSearchPreloaderVisible(false)
                })
        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchValue])


    function hendleSearchChange(e) {
        let inputValue = e.target.value
        setSearchValue(inputValue)
        if (e.target.value === '') {
            setDropDownOpened(false)
        }
        else {
            setDropDownOpened(true)
            setSearchPreloaderVisible(true)
        }
    }

    function hendleKeyDown(e) {
        // console.log(e.keyCode)
        if (e.keyCode === 13 && searchValue) {
            navigate(`/search?value=${searchValue}`)
            Clear()
        }
        if (e.keyCode === 27) {
            Clear()
        }
    }

    function handleDropDownOpen() {
        if (searchValue !== '' && searchValue !== null) setDropDownOpened(true)
    }

    function Clear() {
        setSearchValue('')
        console.log(searchValue)
        setSearchOpen(prevState => !prevState)
        // if (searchValue === '' && searchValue === null && width > 780) {
        //     setSearchOpen(!searchOpen)
        // }
    }
    const textInput = useRef(null);

    useEffect(() => {
        if (searchOpen) {
            textInput.current?.focus();
        }
    }, [searchOpen]);

    useEffect(() => {
        setDropDownOpened(false)
    }, [location])


    return (
        <motion.div
            initial={false}
            animate={{
                width: searchOpen ? maxWidth : 0,
                padding: searchOpen ? width >= 780 ? '0' : '0 16px 8px' : '0',
            }}
            ref={searchRef}
            className={`search__input-container ${searchOpen ? 'search__input-container_visible' : 'search__input-container_unvisible'}`}>
            <div className='search__search-box'>
                <div className='search__input-box' onClick={handleDropDownOpen}>
                    <input onKeyDown={hendleKeyDown} ref={textInput} placeholder="" className="search__input" name="search" type="text" value={searchValue} onChange={hendleSearchChange} maxLength="50"></input>
                    {searchValue && (
                        <Link
                            to={`/search?value=${searchValue}`}
                            className="search__btn-search"
                            onClick={() => {
                                setSearchOpen(false);
                                setSearchValue('');
                            }}
                        >
                            Поиск
                        </Link>
                    )}
                </div>
                <button onClick={() => { Clear() }} className="search__search-icon" type="button">
                    <CloseSearch mainClassName={''} />
                </button>
            </div>
            <SearchDrop searchRef={searchRef} Clear={Clear} isSearchPreloaderVisible={isSearchPreloaderVisible} isOpened={isDropDownOpened} setOpened={setDropDownOpened} searchItems={searchItems} />
        </motion.div>

    );
}

export default Search;