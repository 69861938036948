import "./Address.css";



function Address() {
    
    return (
        <div className={`address`} >
            <div className="address__info">
                <div className="address__container">
                    <div className="address__container-title-box">
                        <svg className="address__container-title-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.12" d="M20 28.3333C22.5 23.3333 30 20.1061 30 13.3333C30 7.8104 25.5228 3.33325 20 3.33325C14.4772 3.33325 10 7.8104 10 13.3333C10 20.1061 17.5 23.3333 20 28.3333Z" fill="#83C452" />
                            <path d="M8.33333 23.8105C5.24772 25.1717 3.33333 27.0685 3.33333 29.1666C3.33333 33.3087 10.7952 36.6666 20 36.6666C29.2047 36.6666 36.6667 33.3087 36.6667 29.1666C36.6667 27.0685 34.7523 25.1717 31.6667 23.8105M30 13.3333C30 20.1061 22.5 23.3333 20 28.3333C17.5 23.3333 9.99999 20.1061 9.99999 13.3333C9.99999 7.8104 14.4771 3.33325 20 3.33325C25.5228 3.33325 30 7.8104 30 13.3333ZM21.6667 13.3333C21.6667 14.2537 20.9205 14.9999 20 14.9999C19.0795 14.9999 18.3333 14.2537 18.3333 13.3333C18.3333 12.4128 19.0795 11.6666 20 11.6666C20.9205 11.6666 21.6667 12.4128 21.6667 13.3333Z" stroke="#83C452" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p className="address__container-title">Самовывоз</p>
                    </div>
                    <div className="address__timetable">
                        <p className="address__timetable-text">г. Москва, ул. Новосущевская, 15</p>
                        <p className="address__timetable-hint">Есть собственная парковка возле магазина</p>
                    </div>
                </div>
                <div className="address__container">
                    <div className="address__container-title-box">
                        <svg className="address__container-title-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.12" d="M30.5621 13.3333H26.6667V26.6666H34C34.9334 26.6666 35.4001 26.6666 35.7567 26.4849C36.0703 26.3251 36.3252 26.0702 36.485 25.7566C36.6667 25.4001 36.6667 24.9333 36.6667 23.9999V19.4378C36.6667 19.0302 36.6667 18.8263 36.6206 18.6345C36.5798 18.4645 36.5125 18.3019 36.4211 18.1528C36.318 17.9846 36.1739 17.8405 35.8856 17.5522L32.4477 14.1143C32.1595 13.826 32.0153 13.6819 31.8471 13.5789C31.698 13.4875 31.5355 13.4201 31.3654 13.3793C31.1736 13.3333 30.9698 13.3333 30.5621 13.3333Z" fill="#83C452" />
                            <path d="M26.6667 26.6667V10.3333C26.6667 8.46649 26.6667 7.53307 26.3033 6.82003C25.9838 6.19282 25.4738 5.68289 24.8466 5.36331C24.1336 5 23.2002 5 21.3333 5H8.66666C6.79982 5 5.8664 5 5.15336 5.36331C4.52615 5.68289 4.01622 6.19282 3.69664 6.82003C3.33333 7.53307 3.33333 8.46649 3.33333 10.3333V21.3333C3.33333 23.2002 3.33333 24.1336 3.69664 24.8466C4.01622 25.4738 4.52615 25.9838 5.15336 26.3034C5.8664 26.6667 6.79982 26.6667 8.66666 26.6667H26.6667ZM26.6667 26.6667H34C34.9334 26.6667 35.4001 26.6667 35.7566 26.485C36.0702 26.3252 36.3252 26.0703 36.485 25.7567C36.6667 25.4001 36.6667 24.9334 36.6667 24V19.4379C36.6667 19.0303 36.6667 18.8264 36.6206 18.6346C36.5798 18.4646 36.5124 18.302 36.4211 18.1529C36.318 17.9847 36.1739 17.8405 35.8856 17.5523L32.4477 14.1144C32.1595 13.8261 32.0153 13.682 31.8471 13.5789C31.698 13.4876 31.5354 13.4202 31.3654 13.3794C31.1736 13.3333 30.9697 13.3333 30.5621 13.3333H26.6667M15 30.8333C15 33.1345 13.1345 35 10.8333 35C8.53214 35 6.66666 33.1345 6.66666 30.8333C6.66666 28.5321 8.53214 26.6667 10.8333 26.6667C13.1345 26.6667 15 28.5321 15 30.8333ZM33.3333 30.8333C33.3333 33.1345 31.4678 35 29.1667 35C26.8655 35 25 33.1345 25 30.8333C25 28.5321 26.8655 26.6667 29.1667 26.6667C31.4678 26.6667 33.3333 28.5321 33.3333 30.8333Z" stroke="#83C452" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p className="address__container-title">Доставка</p>
                    </div>
                    <div className="address__timetable">
                        <p className="address__timetable-text">В&nbsp;течении 5&nbsp;часов с&nbsp;момента получения заказа</p>
                        <p className="address__timetable-hint">При большой загрузке время доставки может быть увеличено. Об&nbsp;этом вас уведомит менеджер.</p>
                    </div>
                </div>
            </div>
            <div className="address__map">
                <iframe title="Яндекс Карта" src="https://yandex.ru/map-widget/v1/?um=constructor%3A1dd65918aa57bdbbd4a64cffaec0b36906dbf58ecf862ca729706eb68b3d19a4&source=constructor" width="100%" height="100%" frameborder="0"></iframe>
            </div>
        </div >
    );
}

export default Address;