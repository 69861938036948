import { NotFoundIcon } from '../../../assets/icons/icons';
import Popup from '../../Popup/Popup';
import './PromoCheckPopup.css';

function PromoCheckPopup({ isOpen, popupName, setOpen, promo }) {
    return (
        <Popup
            isOpen={isOpen}
            popupName={popupName}
            setOpen={setOpen}
        >
            <div className='promo-check-popup'>
                {promo.isValid ?
                    <>
                        <p className='promo-check-popup__discount'>-{Number(promo.discount).toLocaleString()}%</p>
                        <p className='promo-check-popup__text'>Промокод <span className='promo-check-popup__text-string'>{promo.value}</span> на&nbsp;скидку {Number(promo.discount).toLocaleString()}% успешно применен к&nbsp;заказу</p>
                    </>
                    :
                    <>
                        {/* <NotFoundIcon mainClassName={'promo-check-popup__invalid-icon'} fillClassName={'promo-check-popup__invalid-icon-fill'} /> */}
                        <p className='promo-check-popup__text'>К&nbsp;сожалению, данный промокод больше не&nbsp;действительный или не&nbsp;существует</p>
                    </>
                }

            </div>
        </Popup>

    );
}

export default PromoCheckPopup