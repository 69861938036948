import './SelectFilter.css';
import { useContext, useState } from "react";
import { FiltersContext } from "../../../../../assets/contexts/filtersContext";
import { CheckedIcon } from '../../../../../assets/icons/icons';


function SelectFilter({ filter }) {
    const { setFilterValues } = useContext(FiltersContext)

    const handleSelect = (value) => {
        setFilterValues(prevValue => prevValue.map((item) => {
            if (item._id !== filter._id) return item
            else {
                const item_prev_value = item.value ? item.value : []
                const is_value_selected = item_prev_value.indexOf(value) >= 0
                if (is_value_selected) {
                    const new_value = item_prev_value.filter((item) => item !== value)
                    return {
                        ...item,
                        value: new_value,
                    }
                } else {
                    const new_value = item_prev_value.concat([value])
                    return {
                        ...item,
                        value: new_value,
                    }
                }
            }
        }))
    };

    const values = filter.features.values
    return (
        <div className='select-filter'>
            <div className='select-filter__heading'>
                <p className='select-filter__name'>{filter.name}</p>
            </div>
            <div className='select-filter__values'>
                {values && values.length > 0 ?
                    values.map((item, i) => (
                        <button className='select-filter__value' type='button' key={`select-filter__value${filter._id}-${i}`} onClick={() => {
                            handleSelect(item)
                        }}>
                            <div className={`select-filter__value-checkbox ${filter.value && filter.value.length > 0 && filter.value.indexOf(item) >= 0 ? 'select-filter__value-checkbox_selected' : ''}`}>
                                <CheckedIcon mainClassName={'select-filter__value-checkbox-icon'} strokeClassName={'select-filter__value-checkbox-icon-stroke'} />
                            </div>
                            {item}
                        </button>
                    ))
                    :
                    null}
            </div>
        </div>
    );
}

export default SelectFilter