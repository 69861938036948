
import { useContext } from 'react';
import Filter from './Filter/Filter';
import './Filters.css';
import { FiltersContext } from '../../../../assets/contexts/filtersContext';

function Filters() {
    const { filterValues } = useContext(FiltersContext)
    return (
        <div className='filters'>
            {filterValues ?
                filterValues.map((filter, i) => (
                    <Filter
                        filter={filter}
                        key={`filters__${filter._id}${i}`}
                    />
                ))
                : null}
        </div>
    );
}

export default Filters