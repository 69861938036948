
import { useContext } from 'react';
import { FiltersIcon } from '../../../../assets/icons/icons';
import Filters from '../Filters/Filters';
import './FiltersPopup.css';
import { FiltersContext } from '../../../../assets/contexts/filtersContext';
import MobilePopup from '../../../MobilePopup/MobilePopup';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';

function FiltersPopup({ isOpen, handleClose, popupName }) {
    const { handleFiltersApply, handleFiltersReset, isLoading } = useContext(FiltersContext)
    return (
        <MobilePopup
            isOpen={isOpen}
            handleClose={handleClose}
            popupName={popupName}
        >
            <div className='filter-popup'>
                <div className="filter-popup__heading">
                    <p className='filter-popup__title'>Фильтры</p>
                    <FiltersIcon
                        mainClassName={'filter-popup__icon'}
                        strokeClassName={'filter-popup__icon-stroke'}
                    />
                </div>
                <div className='filter-popup__list'>
                    <Filters />
                </div>
                <div className={`filter-popup__btns ${isLoading ? 'filter-popup__btns_loading' : ''}`}>
                    <button className='filter-popup__btn filter-popup__btn_apply' type='button' onClick={() => {
                        handleFiltersApply()
                        handleClose({name: popupName})
                    }}>
                        {isLoading ?
                            <MiniPreloader isLinkColor={true} />
                            :
                            'Применить'
                        }

                    </button>
                    <button className='filter-popup__btn filter-popup__btn_reset' type='button' onClick={handleFiltersReset}>
                        Сбросить
                    </button>
                </div>
            </div>

        </MobilePopup>
    );
}

export default FiltersPopup