import Slider from "@mui/material/Slider";
import './BoolFilterInStock.css';
import { useContext, useState } from "react";
import Toggle from "../../../../Toggle/Toggle";
import { FiltersContext } from "../../../../../assets/contexts/filtersContext";


function BoolFilterInStock({ filter }) {

    const { setFilterValues } = useContext(FiltersContext)
    const handleToggle = () => {
        setFilterValues(prevValue => prevValue.map((item) => {
            if (item._id !== filter._id) return item
            else {
                return {
                    ...item,
                    value: !item.value,
                }
            }
        }))
    };

    return (
        <div className='bool-filter-in-stock'>
            <p className='bool-filter-in-stock__name'>Только в наличии</p>
            <Toggle
                isActive={filter.value}
                handleClick={handleToggle}
                className={'bool-filter-in-stock__toggle'}
            />
        </div>
    );
}

export default BoolFilterInStock