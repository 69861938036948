import { useEffect, useState } from "react";
import "./Input.css";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import { CheckedIcon, CrossIcon } from "../../assets/icons/icons";

function Input({
  index,
  label,
  boxType,
  value,
  name,
  type,
  inputMode = "text",
  handleChange,
  isRequired,
  readOnly = false,
  icon,
  handleVisibility,
  error,
  isTextArea,
  maxRows,
  minRows,
  onKeyDown,
  handleFocus,
  preloader,
  isCorrectState,
  notCorrectAddress,
  hint,
}) {
  const [isFocuced, setIsFocuced] = useState(false);

  useEffect(() => {
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }, [value]);

  function handleFocused() {
    setIsFocuced(true);
  }

  function handleUnfocused(evt) {
    const value = evt.target.value;
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }

  function handlePopupVisibility(evt) {
    handleVisibility(true, evt);
  }

  function handlePopupBlur(evt) {
    handleVisibility(false, evt);
  }

  function handleInputChange(evt) {
    index !== undefined ? handleChange(evt, index) : handleChange(evt);
  }

  function handlePasswordVisibility() {
    handleVisibility(name);
  }

  return (
    <div
      className={`custom-input ${preloader || isCorrectState ? 'custom-input__preloader' : ''} custom-input_type_${boxType ? boxType : name} ${isFocuced || Boolean(value) ? "custom-input_focused" : ""
        } ${readOnly ? "custom-input_disabled" : ""}`}
      onClick={
        name.includes("search")
          ? (evt) => handlePopupVisibility(evt)
          : undefined
      }
      onFocus={
        name.includes("search")
          ? (evt) => handlePopupVisibility(evt)
          : undefined
      }
      onBlur={
        name.includes("search") ? (evt) => handlePopupBlur(evt) : undefined
      }
    >
      <label className="custom-input__label" htmlFor={name}>
        {label}
      </label>

      {isTextArea ? (
        ''
      ) : (
        <input
          className={`custom-input__input ${icon ? "custom-input__input_type_with-icon" : ""
            } ${Boolean(value) ? "custom-input__input_valid" : ""} ${error !== undefined && Boolean(error.errorMessage)
              ? "custom-input__input_error"
              : ""
            }`}
          id={name}
          name={name}
          type={type}
          inputMode={inputMode}
          value={value || ""}
          required={isRequired}
          readOnly={readOnly}
          autoComplete="off"
          onChange={handleInputChange}
          onClick={!readOnly ? handleFocused : undefined}
          onFocus={!readOnly ? handleFocused : undefined}
          onFocusCapture={handleFocused ? handleFocused : null}
          onBlur={handleUnfocused}
          onKeyDown={(e) => {
            if (onKeyDown) {
              onKeyDown(e)
            }
          }}
        />
      )}

      {error !== undefined && Boolean(error.errorMessage) && !hint ? (
        <span className="custom-input__error" dangerouslySetInnerHTML={{
          __html: error.errorMessage
        }}></span>
      ) : null}
      {!error && hint !== undefined && Boolean(hint) ? (
        <span className="custom-input__hint" dangerouslySetInnerHTML={{
          __html: hint
        }}></span>
      ) : null}
      {preloader ?
        <MiniPreloader />
        :
        isCorrectState ?
          notCorrectAddress ?
            <div className="custom-input__icon" onClick={() => {
              handleInputChange({
                target: {
                  name: name,
                  value: '',
                }
              })
            }}>
              <CrossIcon
                mainClassName={'custom-input__icon'}
                fillClassName={'custom-input__icon-fill_error'}
              />
            </div>

            :
            <CheckedIcon
              mainClassName={'custom-input__icon'}
              strokeClassName={'custom-input__icon-stroke'}
            />
          :
          null}

      {name.toLowerCase().includes("password") && icon ? (
        <button
          className="custom-input__password-button"
          type="button"
          aria-label="Toggle password visibility"
          onClick={handlePasswordVisibility}
        >
          {icon}
        </button>
      ) : icon ? (
        <div className="custom-input__icon">{icon}</div>
      ) : null}
    </div>
  );
}

export default Input;
