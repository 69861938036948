import { useContext } from 'react';
import './ShowcaseLine.css';
import { ConfigContext } from '../../../../assets/contexts/configContext';
import ProductCard from '../../../Catalog/ProductCard/ProductCard';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { getRandomId } from '../../../../assets/utils/utils';

export function ShowcaseLine1({
    item
}) {
    return (
        <ShowcaseLine
            item={item}
            lines_count={1}
        />
    );
}


export function ShowcaseLine2({
    item
}) {
    return (
        <ShowcaseLine
            item={item}
            lines_count={2}
        />
    );
}


export function ShowcaseLine3({
    item
}) {
    return (
        <ShowcaseLine
            item={item}
            lines_count={3}
        />
    );
}




function ShowcaseLine({
    item,
    lines_count,
}) {
    const { width } = useWindowSize()
    const config = useContext(ConfigContext)
    const { cardTokens } = config
    const {
        cards_in_line,
    } = cardTokens


    const { title, products } = item.data[0]
    const _id = getRandomId()
    return (
        <div className='showcase-line'>
            {title ? <h2 className='showcase-line__title'>{title}</h2> : null}
            <div className={`showcase-line__items showcase-line__items_cards-in-line-${cards_in_line}`}>
                {products.map((item, i) => (
                    <ProductCard
                        item={item}
                        key={`showcase-line_${_id}products_item_${i}`}
                    />
                ))}

            </div>
        </div>
    );
}

