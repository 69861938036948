import { useEffect } from 'react';



import './MobilePopup.css';

function MobilePopup({ isOpen, children, handleClose, popupName }) {

    return (
        <div className={`mobile-popup__container ${isOpen ? 'mobile-popup__container_active' : ''}`}>
            <div className={`mobile-popup ${isOpen ? 'mobile-popup_active' : ''}`}>
                {children}
            </div>
            <div className='mobile-popup__bg' onClick={() => {
                if (!popupName) {
                    handleClose()
                } else {
                    handleClose({ name: popupName })
                }
            }}></div>
        </div>

    );
}

export default MobilePopup;