import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import mainApi from "../../../assets/api/MainApi";
import { ArrowIcon, CatalogIcon, CloseIcon } from "../../../assets/icons/icons";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

import "./MenuPopup.css";
import { CATALOG_MAIN_LINK } from "../../../assets/utils/constants";
import { ConfigContext } from "../../../assets/contexts/configContext";

function MenuPopup({ isOpened, setOpened, categories }) {
  const [selectedCategory, setSelectCategory] = useState(undefined);
  const [isPreloaderVisible, setPreloaderVisible] = useState(false);
  const [subcategories, setSubcategories] = useState(undefined);

  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { SHOP_ID_FOR_API, } = initialConstants

  function close() {
    console.log("sas");
    setOpened(false);
    setTimeout(() => {
      setOpened(false);
    }, 1);
  }

  const navigate = useNavigate()
  function handleSelectCategory(item) {
    if (selectedCategory && selectedCategory._id === item._id) {
      navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
      close()
      return
    };
    if (item.is_final) {
      navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
      setSelectCategory(item);
      setSubcategories(undefined)
      close()
      return
    }

    console.log(item);
    setSelectCategory(item);
    setPreloaderVisible(true);
    mainApi
      .getCategories({
        limit: 25,
        shop_id: SHOP_ID_FOR_API,
        _id: item._id,
      })
      .then((res) => {
        console.log(res.data);
        setSubcategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaderVisible(false);
      });
  }


  const [selectedType, setSelectedType] = useState("catalog");

  return (
    <>
      {categories && categories.length > 0 && Array.isArray(categories) ? (
        <div
          className={`menu-popup__container ${isOpened ? "menu-popup__container_active" : ""
            }`}
        >
          <div
            className={`menu-popup ${isOpened ? "menu-popup_active" : "menu-popup_inactive"
              }`}
          >
            <button className="menu-popup__close" type="button" onClick={close}>
              <CloseIcon
                mainClassName={"menu-popup__close-icon"}
                fillClassName={"menu-popup__close-icon-fill"}
              />
            </button>
            {selectedType === "catalog" ? (
              <div className="menu-popup__cards">
                {categories.map((item, i) => (
                  <div className="menu-popup__card-box">
                    <div
                      className={`menu-popup__card ${selectedCategory && selectedCategory._id === item._id
                          ? "menu-popup__card_selected"
                          : ""
                        }`}
                      key={`menu-popup__card${selectedType}${i}${item._id}`}
                      onClick={() => {
                        handleSelectCategory(item);
                      }}
                    >
                      <div className="menu-popup__card-info">
                        <p className="menu-popup__card-name">{item.name}</p>
                      </div>
                      <ArrowIcon
                        mainClassName={"menu-popup__card-arrow"}
                        fillClassName={"menu-popup__card-arrow-fill"}
                      />
                    </div>
                    {selectedCategory && selectedCategory._id === item._id ? (
                      isPreloaderVisible ? (
                        <div className="menu-popup__card-preloader">
                          <MiniPreloader />
                        </div>
                      ) : subcategories && subcategories.length > 0 ? (
                        <div className="menu-popup__card-subcategories">
                          {subcategories.map((sub, i) => (
                            <Link
                              className="menu-popup__card-subcategory"
                              to={`/${CATALOG_MAIN_LINK}/${selectedCategory.translit_name}/${sub.translit_name}`}
                              onClick={close}
                            >
                              {sub.name}
                              {/* <ArrowIcon mainClassName={'menu-popup__card-subcategory-arrow'} fillClassName={'menu-popup__card-arrow-fill'} /> */}
                            </Link>
                          ))}
                        </div>
                      ) : null
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MenuPopup;
