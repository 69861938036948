import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import mainApi from "../../../assets/api/MainApi";
import { ArrowIcon } from "../../../assets/icons/icons";
import { getIconByType } from "../../../assets/utils/utils";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

import "./CatalogDrop.css";
import { CATALOG_MAIN_LINK } from "../../../assets/utils/constants";
import { ConfigContext } from "../../../assets/contexts/configContext";

function CatalogDrop({ isOpened, setOpened, categories, catalogBtnRef }) {
  const dorpRef = useRef();
  const navigate = useNavigate();

  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { SHOP_ID_FOR_API, } = initialConstants

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu

      if (isOpened && dorpRef.current && !dorpRef.current.contains(e.target)) {
        setOpened(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpened]);

  useEffect(() => {
    // catalogBtnRef.current.onclick = null;
    if (isOpened) {
      catalogBtnRef.current.onclick = () => {
        setOpened(false);
      };
    } else {
      setTimeout(() => {
        catalogBtnRef.current.onclick = () => {
          setOpened(true);
        };
      }, 300);
    }
  }, [isOpened]);

  const [selectedCategory, setSelectCategory] = useState(undefined);
  const [isPreloaderVisible, setPreloaderVisible] = useState(false);
  const [subcategories, setSubcategories] = useState(undefined);

  function closeDropdown() {
    console.log("sas");
    setOpened(false);
    setTimeout(() => {
      setOpened(false);
    }, 1);
  }

  function handleSelectCategory(item) {
    console.log(item)
    if (selectedCategory && selectedCategory._id === item._id) {
      navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
      closeDropdown()
      return
    };
    if (item.is_final) {
      navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
      setSelectCategory(item);
      setSubcategories(undefined)
      closeDropdown()
      return
    }
    setSelectCategory(item);
    setPreloaderVisible(true);

    mainApi
      .getCategories({
        limit: 25,
        shop_id: SHOP_ID_FOR_API,
        _id: item._id,
      })
      .then((res) => {
        setSubcategories(res.data);
        if (res.data.length === 0) {
          navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
          closeDropdown()
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaderVisible(false);
      });
  }


  // useEffect(() => {
  //   if (!categories) return
  //   const filteredCategories = categories ? categories.filter((item) => item.type) : undefined
  //   let item = filteredCategories[0]
  //   setSelectCategory(item)
  //   setPreloaderVisible(true)
  //   mainApi.getSubcategoriesByCategory({
  //     category_translit_name: item.translit_name,
  //     limit: 25,
  //   })
  //     .then((res2) => {
  //       console.log(res2.data)
  //       setSubcategories(res2.data)
  //       // setSelectedCategory(res2.category)
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  //     .finally(() => {
  //       setPreloaderVisible(false)
  //     })
  // }, [categories])

  return (
    <>
      {categories && categories.length > 0 && Array.isArray(categories) ? (
        <div
          className={`catalog-drop__container ${isOpened ? "catalog-drop__container_active" : ""
            }`}
        >
          <div
            ref={dorpRef}
            className={`catalog-drop ${isOpened ? "catalog-drop_active" : "catalog-drop_inactive"
              }`}
          >
            <div className="catalog-drop__content">
              <div className="catalog-drop__categories">
                {categories.map((item, i) => (
                  <div
                    className={`catalog-drop__card ${selectedCategory && selectedCategory._id === item._id
                      ? "catalog-drop__card_selected"
                      : ""
                      }`}
                    key={`catalog-drop__card${i}${item._id}`}
                    onClick={() => {
                      handleSelectCategory(item);
                    }}
                  >
                    <div className="catalog-drop__card-info">
                      <p className="catalog-drop__card-name">{item.name}</p>
                    </div>
                    <ArrowIcon
                      mainClassName={"catalog-drop__card-arrow"}
                      fillClassName={"catalog-drop__card-arrow-fill"}
                    />
                  </div>
                ))}
              </div>

              {isPreloaderVisible ? (
                <div className="catalog-drop__preloader">
                  <MiniPreloader />
                </div>
              ) : (
                <div className="catalog-drop__subcategories">
                  {subcategories && subcategories.length > 0
                    ? subcategories.map((item, i) => (
                      <Link
                        className="catalog-drop__subcategory"
                        to={`/${CATALOG_MAIN_LINK}/${selectedCategory.translit_name}/${item.translit_name}`}
                        onClick={closeDropdown}
                      >
                        {item.name}
                      </Link>
                    ))
                    : null}
                </div>
              )}
            </div>
          </div>
          {/* <div className={`catalog-drop__background ${isOpened ? 'catalog-drop__background_active' : ''}`} onClick={closeDropdown}></div> */}
        </div>
      ) : null}
    </>
  );
}

export default CatalogDrop;
