
import "./Shcedule.css";



function Shcedule() {

    return (
        <div className={`schedule`} >
            <div className="schedule__container">
                <div className="schedule__container-title-box">
                    <svg className="schedule__container-title-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.12" d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z" fill="#83C452" />
                        <path d="M37.8333 19.1667L34.5009 22.5L31.1666 19.1667M34.9085 21.6667C34.9689 21.1194 35 20.5633 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35C24.7121 35 28.9167 32.8272 31.6667 29.4289M20 11.6667V20L25 23.3333" stroke="#83C452" stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p className="schedule__container-title">График работы</p>
                </div>
                <div className="schedule__timetable">
                    <p className="schedule__timetable-text"><b>Понедельник&nbsp;&mdash; четверг</b>: с&nbsp;9:00&nbsp;до&nbsp;22:00</p>
                    <div className="schedule__timetable-separator" />
                    <p className="schedule__timetable-text"><b>Пятница</b>: закрываемся ровно за&nbsp;час до&nbsp;наступления Шаббата</p>
                    <p className="schedule__timetable-hint">(см. когда наступает Шаббат, время каждый раз разное)</p>
                    <div className="schedule__timetable-separator" />
                    <p className="schedule__timetable-text"><b>Суббота</b>: нерабочий день</p>
                    <p className="schedule__timetable-text"><b>Воскресенье</b>: с&nbsp;9:00&nbsp;до&nbsp;22:00</p>
                </div>
            </div>
            <div className="schedule__container">
                <div className="schedule__container-title-box">
                    <svg className="schedule__container-title-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.12" d="M20.0006 35.665C28.6522 35.665 35.6656 28.6515 35.6656 20C35.6656 11.3484 28.6522 4.33496 20.0006 4.33496C11.3491 4.33496 4.33563 11.3484 4.33563 20C4.33563 28.6515 11.3491 35.665 20.0006 35.665Z" fill="#FA3D3D" />
                        <path d="M35.0453 21.9183C35.1246 21.29 35.1654 20.6498 35.1654 20C35.1654 11.6246 28.3758 4.83496 20.0004 4.83496C11.6251 4.83496 4.83545 11.6246 4.83545 20C4.83545 28.3754 11.6251 35.165 20.0004 35.165C20.6607 35.165 21.3112 35.1228 21.9492 35.0409M20.0004 10.901V20L25.6697 22.8346M26.0664 30.6155H35.1654" stroke="#FA3D3D" stroke-width="3.033" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p className="schedule__container-title">Внимание</p>
                </div>
                <div className="schedule__timetable">
                    <p className="schedule__timetable-text">В&nbsp;пятницу заканчиваем получать заказы за&nbsp;5&nbsp;часов до&nbsp;наступления Шаббата</p>
                    <p className="schedule__timetable-hint">Если вы&nbsp;сделали заказ позже&nbsp;&mdash; мы&nbsp;доставим его вам в&nbsp;воскресенье в&nbsp;удобное для вас время</p>
                </div>
            </div>
        </div >
    );
}

export default Shcedule;