export const RETURN_POLICY_TEXT = `
Купили у нас товар и хотите вернуть? Не проблема!

Обратиться за возвратом денежных средств вы можете в течение 7 дней с момента покупки в интернет-магазине. Для того чтобы произвести возврат товара, который не подошел вам по размеру, модели или цвету, нужно написать заявление на возврат денежных средств и обратиться в отдел претензий по телефону: +7(915) 024-06-73, либо отправить e-mail на denis@smktrade.ru. Ваша заявка будет рассмотрена в течение 7 рабочих дней. Денежные средства будут возвращены вам путем, аналогичным оплате заказа. Товар должен быть абсолютно новым, то есть ни разу не использованным. Даже одна поездка на велосипеде не позволит вам вернуть деньги. Услуга вывоза товара нашими курьерами платная. Стоимость сообщит менеджер при согласовании даты приезда курьера.

Возврат / обмен бракованного товара осуществляется через отдел претензий, с которым можно связаться по телефону: +7(915) 024-06-73, либо по e-mail: denis@smktrade.ru. В случае, если по результатам диагностики выявляется заводской брак товара, то услуги забора и доставки товара на замену, а также диагностика оплачиваются продавцом.

В случае, когда по итогам диагностики выявляется, что поломка товара была получена в процессе эксплуатации, товар замене не подлежит. Транспортные услуги и диагностика оплачиваются клиентом. Также вы можете обратиться в сторонний сервис, в этом случае диагностика будет платной, согласно прейскуранту выбранного вами сервиса.

Производитель или поставщик оставляет за собой право изменить технические характеристики без предварительного уведомления.

*Внимание! Если покупатель по каким-либо причинам (не подошел размер или модель) отказывается от товара, но товар соответствует заказу и не имеет дефектов, клиент обязан оплатить полную стоимость доставки. (п.21 Правил продажи товаров дистанционным способом, утвержденных Постановлением Правительства РФ от 27.09.2007 г. № 612 "При отказе покупателя от товара продавец должен возвратить ему сумму, уплаченную покупателем в соответствии с договором, за исключением расходов продавца на доставку от покупателя возвращенного товара, не позднее чем через 10 дней с даты предъявления покупателем соответствующего требования." Абзац 5 п. 4 ст. 26,1 Закона о защите прав потребителей. П. 3 и 4 ст. 497 ГК Р. )
`