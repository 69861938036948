import { Link } from "react-router-dom";
import "./MakeOrder.css";

function MakeOrder({ }) {
    const MAKE_ORDER = [
        {
            logo: 'https://i.ibb.co/6gmk3LZ/Frame.png',
            title: 'Оформите заказ в интернет-магазине',
            decs: 'Добавьте ковёр в корзину и заполните форму заказа',
        },
        {
            logo: 'https://i.ibb.co/RbQMZC0/Capa-1.png',
            title: 'Дождитесь подтверждения заказа',
            decs: 'Наш оператор подтвердит наличии выбранного Вами ковра на складе',
        },
        {
            logo: 'https://i.ibb.co/5WSR2fD/Capa-12.png',
            title: 'Получите счёт на оплату',
            decs: 'Мы выставляем Вам счёт за ковёр. Наш менеджер посчитает конечную стоимость с учётом доставки',
        },
        {
            logo: 'https://i.ibb.co/X2n9grD/123.png',
            title: 'Оплатите счёт',
            decs: 'Банковской картой или безналичным переводом',
        },
        {
            logo: 'https://i.ibb.co/9pqDm0y/Layer-1.png',
            title: 'Ваш ковёр едет к вам',
            decs: 'По факту поступления оплаты мы произведём отгрузку товара на Ваш адрес в течение 2 дней',
        },
    ]

    return (
        <div className={`make-order`} >
            <div className={`make-order__container`}>
                <h1 className="make-order__title">Как сделать заказ</h1>
                <div className="make-order__box_card">
                    {MAKE_ORDER.map((item, i) => {
                        return (
                            <div key={i} className={`make-order__card`}>
                                <div className="make-order__card__logo-box" style={{ position: 'relative', }}>
                                    <img src={item.logo} alt="" className="make-order__card__img"></img>
                                    <h2 className="make-order__card__number">{i + 1}</h2>
                                </div>
                                <div className="make-order__card__text">
                                    <h3>{item.title}</h3>
                                    <p className="make-order__card__decs">{item.decs}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* <Link to={'/delivery'} className="make-order__btn">Условия доставки</Link> */}
            </div>
        </div >
    );
}

export default MakeOrder;