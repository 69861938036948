import { useContext, useEffect, useState } from "react";
import "./Products.css";
import { ConfigContext } from "../../../assets/contexts/configContext";
import ProductCard from "../ProductCard/ProductCard";
import Filters from "./Filters/Filters";
import FilterBox from "./FilterBox/FilterBox";
import { FiltersContext } from "../../../assets/contexts/filtersContext";
import useWindowSize from "../../../assets/hooks/useWindowSize";
import { FiltersIcon } from "../../../assets/icons/icons";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

function Products({ items, path, isSearch }) {
  const { width } = useWindowSize()
  const config = useContext(ConfigContext);
  const { cardTokens, initialConstants } = config;
  const { cards_in_line } = cardTokens;
  const { USING_FILTERS } = initialConstants
  // const USING_FILTERS = true

  const { filterValues, handleOpenFilterPopup, isLoading } = useContext(FiltersContext) || {}

  const IS_FILTERS_AVAILIBLE = USING_FILTERS && filterValues ? true : false

  return (
    <div className={`products ${IS_FILTERS_AVAILIBLE ? 'products_with-filters' : ''}`}>
      {IS_FILTERS_AVAILIBLE ?
        <div className='products__filters'>
          <FilterBox />
        </div>
        : null}

      {IS_FILTERS_AVAILIBLE ?
        <div className="products__heading">
          <button className='products__filters-btn' type="button" onClick={handleOpenFilterPopup}>
            Фильтры
            <FiltersIcon
              mainClassName={'products__filters-btn-icon'}
              strokeClassName={'products__filters-btn-icon-stroke'}
            />
          </button>
        </div>

        : null}

      {!isLoading ?
        items?.data?.length > 0
          ?
          <div
            className={`products__items products__items_cards-in-line-${cards_in_line - (width > 900 && IS_FILTERS_AVAILIBLE ? 1 : 0)}`}
          >
            { // Array.from({ length: cards_in_line * 3 }).map((item, i) => i)
              items.data.map((item, i) => (
                <ProductCard
                  item={item}
                  key={`catalog_products_item_${item._id}`}
                  path={path}
                />
              ))
            }
          </div>
          :
          <div className="products__items-empty">
            <p>По&nbsp;вашему запросу ничего не&nbsp;найдено</p>
          </div>
        :
        <div className="products__items-preloader">
          <MiniPreloader />
        </div>
      }

    </div >
  );
}

export default Products;
