
import "./SubmitActionPopup.css";
import React, { useEffect, useState } from "react";

function SubmitActionPopup({
  isOpen,
  hendleClose,
  hendleSubmit,
  text,
  submitText,
  cencelText,
  isSubmitPreloader,
  submitError,
}) {
  const [step, setStep] = useState(0)



  return (
    <div
      className={`submit-act-popup ${isOpen ? "submit-act-popup_active" : ""}`}
    >
      <div
        className={`submit-act-popup__container ${isOpen ? "submit-act-popup__container_active" : ""
          }`}
      >
        {step === 0 ?
          <>
            <svg className="submit-act-popup__icon" xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
              <circle cx="52" cy="52" r="51" stroke="black" stroke-width="2"></circle>
              <path d="M32.8104 65.6257H29.2133V42.5481L22 45.1123V41.9679L32.2495 38.2433H32.8104V65.6257Z" fill="black"></path>
              <path d="M60.3681 45.4866C60.3681 46.8467 59.9942 48.057 59.2464 49.1176C58.5115 50.1783 57.5124 51.008 56.2489 51.607C57.7187 52.2184 58.879 53.1105 59.7299 54.2834C60.5937 55.4563 61.0256 56.7852 61.0256 58.2701C61.0256 60.6283 60.2005 62.5062 58.5502 63.9037C56.9129 65.3012 54.7534 66 52.0717 66C49.3643 66 47.1919 65.3012 45.5546 63.9037C43.9301 62.4938 43.1179 60.6159 43.1179 58.2701C43.1179 56.7977 43.5305 55.4688 44.3556 54.2834C45.1936 53.098 46.3475 52.1996 47.8172 51.5882C46.5666 50.9893 45.5804 50.1595 44.8584 49.0989C44.1364 48.0383 43.7754 46.8342 43.7754 45.4866C43.7754 43.1907 44.5361 41.369 46.0574 40.0214C47.5787 38.6738 49.5835 38 52.0717 38C54.5471 38 56.5454 38.6738 58.0667 40.0214C59.601 41.369 60.3681 43.1907 60.3681 45.4866ZM57.4479 58.1952C57.4479 56.6729 56.9451 55.4314 55.9395 54.4706C54.9468 53.5098 53.6446 53.0294 52.0331 53.0294C50.4215 53.0294 49.1258 53.5036 48.146 54.4519C47.179 55.4002 46.6956 56.648 46.6956 58.1952C46.6956 59.7424 47.1661 60.959 48.1073 61.8449C49.0613 62.7308 50.3828 63.1738 52.0717 63.1738C53.7478 63.1738 55.0628 62.7308 56.0168 61.8449C56.9709 60.9465 57.4479 59.7299 57.4479 58.1952ZM52.0717 40.8449C50.6665 40.8449 49.5255 41.2692 48.6488 42.1176C47.785 42.9537 47.3531 44.0954 47.3531 45.5428C47.3531 46.9278 47.7785 48.0508 48.6294 48.9118C49.4932 49.7603 50.6407 50.1845 52.0717 50.1845C53.5028 50.1845 54.6438 49.7603 55.4947 48.9118C56.3585 48.0508 56.7904 46.9278 56.7904 45.5428C56.7904 44.1578 56.3456 43.0285 55.456 42.1551C54.5664 41.2816 53.4383 40.8449 52.0717 40.8449Z" fill="black"></path>
              <path d="M76.1485 51.008H84V54.2834H76.1485V62.893H72.5515V54.2834H64.6999V51.008H72.5515V43.0535H76.1485V51.008Z" fill="black"></path>
            </svg>
            <p className="submit-act-popup__title">Подтвердите свой возраст</p>
            <p className="submit-act-popup__text">Данная категория содержит алкогольную продукцию. Для просмотра подтвердите, что вам уже исполнилось 18&nbsp;полных лет.</p>
            <div className="submit-act-popup__btns">
              <button
                className="submit-act-popup__btn submit-act-popup__btn_disagree"
                type="button"
                onClick={hendleClose}
              >
                Вернуться
              </button>
              <button
                className="submit-act-popup__btn submit-act-popup__btn_agree"
                type="button"
                onClick={() => {
                  setStep(1)
                }
                }
              >
                Подтверждаю
              </button>
            </div>
          </>
          : null}
        {step === 1 ?
          <>
            <p className="submit-act-popup__title submit-act-popup__title_second">Алкоголь теперь можно приобрести на&nbsp;доставке!</p>
            <p className="submit-act-popup__text">После оформления заказа, в&nbsp;котором есть алкогольная продукция, с&nbsp;вами свяжется наш менеджер.<br /><br />
              <b>Обратите внимание!</b> При получении заказа курьер попросит вас показать паспорт. Оплата за&nbsp;алкоголь осуществляется переводом на&nbsp;карту.</p>
            <a className="submit-act-popup__phone" href="tel:+74951236770">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.0145 20.672L22.3235 18.981C21.4787 18.1362 20.1092 18.1362 19.2659 18.981L17.9367 20.3102C17.6397 20.6071 17.19 20.7066 16.8065 20.5379C14.8805 19.6946 12.9776 18.4159 11.2808 16.7192C9.59128 15.0296 8.3169 13.1353 7.47212 11.2166C7.29625 10.8201 7.3986 10.3545 7.70566 10.0474L8.89643 8.85668C9.86374 7.88937 9.86374 6.5213 9.01896 5.67652L7.32796 3.98553C6.20207 2.85964 4.377 2.85964 3.25111 3.98553L2.31119 4.92401C1.24296 5.99223 0.797507 7.5333 1.08583 9.06139C1.79798 12.8283 3.98633 16.9527 7.51681 20.4832C11.0473 24.0136 15.1717 26.202 18.9386 26.9141C20.4667 27.2024 22.0078 26.757 23.076 25.6888L24.0145 24.7503C25.1404 23.6244 25.1404 21.7993 24.0145 20.672Z" fill="black" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.416 6.80522C16.9023 6.78504 18.3958 7.33573 19.5303 8.47027" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M23.6072 4.39352C21.3453 2.13165 18.3799 1 15.416 1" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21.1943 12.5848C21.2145 11.0985 20.6638 9.605 19.5293 8.47046" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M23.6074 4.39355C25.8693 6.65542 27.001 9.62079 27.001 12.5847" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              +7 (495) 123-67-70</a>
            <div className="submit-act-popup__btns submit-act-popup__btns_second-step">
              <button
                className="submit-act-popup__btn submit-act-popup__btn_agree"
                type="button"
                onClick={hendleSubmit}
              >
                Мне все понятно
              </button>
            </div>
          </>
          : null}

      </div>
      <div
        className={`submit-act-popup__background ${isOpen ? "submit-act-popup__background_active" : ""
          }`}
      ></div>
    </div>
  );
}

export default SubmitActionPopup;
