export const PAYMENT_AND_DELIVERY_TEXT = `
Способы доставки

Самовывоз из магазина
Москва, ул. Новосущёвская, 15

Забрать заказ можно бесплатно в рабочие часы по адресу: г. Москва, ул. Новосущёвская, 15 – в день заказа при условии наличия товара в выбранном магазине. При получении товара обязательно проверьте его целостность. Дополнительные детали вы всегда можете уточнить, позвонив в магазин по телефону +7 915 024-06-73
Доставка по Москве и ближайшему Подмосковью
·	Доставка курьером возможна от любой суммы, минимальной суммы заказа нет.
·	Доставка осуществляется до двери в течение 5ч после подтверждения заказа, указать желаемое время доставки можно в комментарии к заказу или по телефону +7 915 024-06-73
·	Курьер заранее свяжется с вами для подтверждения времени доставки.
·	Время ожидания курьера на указанном адресе не более 15 минут.

Способы оплаты
Онлайн банковской картой
Для оплаты (ввода реквизитов вашей карты) вы будете перенаправлены на платёжный шлюз ЮKassa. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа также может потребоваться ввод специального пароля.

Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ЮKassa. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB. Онлайн-оплата по банковской карте происходит через ЮKassa с использованием следующих платёжных систем:

Безопасность платежей с помощью банковских карт обеспечивается технологиями защищенного соединения HTTPS и двухфакторной аутентификации пользователя 3D Secure. В соответствии с ФЗ «О защите прав потребителей» в случае, если вам была оказана услуга или реализован товар ненадлежащего качества, платёж может быть возвращён на банковскую карту, с которой производилась оплата. Порядок возврата средств уточняйте у администрации интернет-магазина.


Наличными
Вы можете оплатить заказ наличными при получении, для этого на странице оформления заказа выберите соответствующий способ.

Оплата на расчётный счёт
Укажите реквизиты для выставления счёта при оформлении заказа или свяжитесь с нами для уточнения деталей.
`