import Slider from "@mui/material/Slider";
import './RangeFilter.css';
import { useContext, useState } from "react";
import { FiltersContext } from "../../../../../assets/contexts/filtersContext";

// format position label
function valueLabelFormat(value) {
    return value.toFixed(2);
}

function calculateStepSize(gte, lte) {
    // Calculate the range
    const range = lte - gte;

    // Calculate an initial step size based on the range
    // The factor can be adjusted to increase or decrease the step size.
    const step = Math.pow(10, Math.floor(Math.log10(range)) - 1);

    // Ensure the step size is at least 1 (you can adjust this based on your requirements)
    return Math.max(step, 1);
}

function RangeFilter({ filter }) {
    const { setFilterValues } = useContext(FiltersContext)
    const GTE = filter.features.gte
    const LTE = filter.features.lte

    const STEP = calculateStepSize(GTE, LTE);

    const handleChange = (event, newValue) => {
        setFilterValues(prevValue => prevValue.map((item) => {
            if (item._id !== filter._id) return item
            else {
                return {
                    ...item,
                    value: newValue,
                }
            }
        }))
    };
    const VALUE = filter.value
    if (GTE === LTE) return null
    return (
        <div className='range-filter'>
            <div className='range-filter__heading'>
                <p className='range-filter__name'>{filter.name}</p>

            </div>
            <div className='range-filter__selector'>
                <Slider
                    aria-label=""
                    min={GTE}
                    max={LTE}
                    step={STEP}
                    valueLabelDisplay="off"
                    valueLabelFormat={valueLabelFormat}
                    defaultValue={1}
                    value={VALUE}
                    onChange={handleChange}
                />
            </div>
            <div className="range-filter__selector-values">
                <p className="range-filter__selector-value">{VALUE[0]}</p>
                <p className="range-filter__selector-value">{VALUE[1]}</p>
            </div>
        </div>
    );
}

export default RangeFilter