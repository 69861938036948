import { useContext, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Navigation } from "swiper";

// import { motion } from 'framer-motion'

import "swiper/css";
import "swiper/css/pagination";


import './ShowcaseScroll.css';
import { ConfigContext } from '../../../../assets/contexts/configContext';
import ProductCard from '../../../Catalog/ProductCard/ProductCard';
import { getRandomId } from '../../../../assets/utils/utils';
import useWindowSize from '../../../../assets/hooks/useWindowSize';

function ShowcaseScroll({
    item,
}) {

    const config = useContext(ConfigContext)
    const { cardTokens } = config
    const {
        cards_in_line,
    } = cardTokens
    const { width } = useWindowSize()
    console.log(item)
    const { title, products } = item.data[0]
    const [my_swiper, set_my_swiper] = useState({});
    const _id = getRandomId()
    return (
        <div className='showcase-scroll'>
            {title ? <h2 className='showcase-scroll__title'>{title}</h2> : null}
            <div className='showcase-scroll__items-slider'>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={width > 800 ? 16 : 8}

                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                        dynamicMainBullets: 4,
                    }}
                    mousewheel={{
                        forceToAxis: true,
                    }}

                    modules={[Mousewheel, Pagination]}
                    preventInteractionOnTransition={true}
                    className="showcase-scroll__items"
                    onInit={(ev) => {
                        set_my_swiper(ev)
                    }}

                >
                    {products.map((slide, i2) => (
                        <SwiperSlide className="showcase-scroll__item" key={`promo-100-${item._id}-slide-${i2}`}>
                            <ProductCard
                                item={slide}
                                key={`showcase-scroll_${_id}products_item_${i2}`}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default ShowcaseScroll