import { Link } from 'react-router-dom';
// import { NotFoundIcon } from '../../assets/icons/icons';
import './NotFoundBlock.css';

function NotFoundBlock({ text, className, goToMain }) {
    return (
        <div className={`not-found-block ${className ? className : ''}`}>
            {/* <NotFoundIcon mainClassName={'not-found-block__icon'} fillClassName={'not-found-block__icon-fill'} /> */}
            <p className='not-found-block__text'  dangerouslySetInnerHTML={{
                        __html: text,
                    }}></p>
            {goToMain ?
                <Link className="not-found-block__go-back" to={-1}>
                    ‹ Вернуться назад
                </Link>
                : null}
        </div>
    );
}

export default NotFoundBlock