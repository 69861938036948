import { Helmet } from "react-helmet";
import { useSearchParams } from 'react-router-dom';
import './SearchPage.css';
import { useContext, useEffect, useState } from 'react';
import mainApi from '../../assets/api/MainApi';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import Products from '../Catalog/Products/Products';
import { ConfigContext } from "../../assets/contexts/configContext";

function SearchPage() {
    const config = useContext(ConfigContext)
    const { initialConstants } = config
    const { SHOP_ID_FOR_API, SHOP_NAME } = initialConstants

    const [searchParams, setSearchParams] = useSearchParams();
    const value = searchParams.get("value");
    const [searchItems, setSearchItems] = useState([])
    const [isSearchPreloaderVisible, setSearchPreloaderVisible] = useState(true)

    useEffect(() => {
        setSearchPreloaderVisible(true)
        mainApi.getSearch({
            shop_id: SHOP_ID_FOR_API,
            string: value.toLowerCase(),
            limit: 25,
        })
            .then((res) => {
                console.log(res.data)
                setSearchItems(res.data)
                // setSearchItems(RESULT_SEARCH)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setSearchPreloaderVisible(false)
            })
    }, [value])

    return (
        <div className={`search-page`}>
            <div className='search-page__content'>
                {isSearchPreloaderVisible ?
                    <div className='search-drop__preloader'>
                        <Helmet>
                            <title>{SHOP_NAME} - ...</title>
                        </Helmet>
                        <MiniPreloader />
                    </div>
                    :
                    <>
                        <Helmet>
                            <title>{SHOP_NAME} - Поиск: {value}</title>
                        </Helmet>
                        <h2 className='search-page__title'>Поиск: {value}</h2>
                        <Products
                            items={{ data: searchItems }}
                            path={'/'}
                        />
                    </>}
            </div>

        </div>
    );
}

export default SearchPage