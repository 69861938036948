import { useContext } from 'react';
import './ShowcaseLineImage.css';
import { ConfigContext } from '../../../../assets/contexts/configContext';
import ProductCard from '../../../Catalog/ProductCard/ProductCard';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { getRandomId } from '../../../../assets/utils/utils';
import { IMG_PLACEHOLDER_PROMO } from '../../../../assets/utils/constants';

export function ShowcaseLineImage1({
    item
}) {
    return (
        <ShowcaseLineImage
            item={item}
            lines_count={1}
        />
    );
}


export function ShowcaseLineImage2({
    item
}) {
    return (
        <ShowcaseLineImage
            item={item}
            lines_count={2}
        />
    );
}


export function ShowcaseLineImage3({
    item
}) {
    return (
        <ShowcaseLineImage
            item={item}
            lines_count={3}
        />
    );
}




function ShowcaseLineImage({
    item,
    lines_count,
}) {
    const { width } = useWindowSize()
    const config = useContext(ConfigContext)
    const { cardTokens } = config
    const {
        cards_in_line,
    } = cardTokens


    const { title, products, image } = item.data[0]
    const _id = getRandomId()
    return (
        <div className='showcase-line-image'>
            <div className='showcase-line-image__img-box'>
                {title ? <h2 className='showcase-line-image__title'>{title}</h2> : null}
                <img
                    className="showcase-line-image__img"
                    // src={`${MAIN_URL}/get-file/${item.file_path.orig}`}
                    src={`${image ? image.urls.orig : IMG_PLACEHOLDER_PROMO}`}
                    alt=""></img>
            </div>

            <div className={`showcase-line-image__items showcase-line-image__items_cards-in-line-${cards_in_line}`}>
                {products.map((item, i) => (
                    <ProductCard
                        item={item}
                        key={`showcase-line-image_${_id}products_item_${i}`}
                    />
                ))}

            </div>
        </div>
    );
}

