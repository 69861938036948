import { ManagerIcon } from '../../../assets/icons/icons';
import Popup from '../../Popup/Popup';
import './OrderPopup.css';

function OrderPopup({ isOpen, popupName, setOpen, ai_id, order }) {
    return (
        <Popup
            isOpen={isOpen}
            popupName={popupName}
            setOpen={setOpen}
        >
            <div className='oreder-popup'>
                <ManagerIcon mainClassName={'oreder-popup__icon'} fillClassName={'oreder-popup__icon_fill'} />
                <p className="oreder-popup__text">Вы&nbsp;успешно оформили заказ {ai_id ? ai_id : ''}</p>
                <p className="oreder-popup__subtext">Наши менеджеры уже приступили к его обработке</p>
            </div>
        </Popup>

    );
}

export default OrderPopup